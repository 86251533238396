import { MessageMap } from 'deliver-portal-commons';

const es: MessageMap = {
  Hive_CountriesLabel: {
    AD: 'Andorra',
    AE: 'Emiratos Árabes Unidos',
    AF: 'Afganistán',
    AG: 'Antigua y Barbuda',
    AI: 'Anguila',
    AL: 'Albania',
    AM: 'Armenia',
    AO: 'Angola',
    AQ: 'Antártida',
    AR: 'Argentina',
    AS: 'Samoa Americana',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AZ: 'Azerbaiyán',
    BA: 'Bosnia y Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladés',
    BE: 'Bélgica',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Baréin',
    BI: 'Burundi',
    BJ: 'Benín',
    BL: 'San Bartolomé',
    BM: 'Bermudas',
    BN: 'Brunéi',
    BO: 'Bolivia',
    BR: 'Brasil',
    BS: 'Bahamas',
    BT: 'Bután',
    BW: 'Botsuana',
    BY: 'Bielorrusia',
    BZ: 'Belice',
    CA: 'Canadá',
    CC: 'Islas Cocos',
    CD: 'República Democrática del Congo',
    CF: 'República Centroafricana',
    CG: 'República del Congo',
    CH: 'Suiza',
    CI: 'Costa de Marfil',
    CL: 'Chile',
    CM: 'Camerún',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cabo Verde',
    CW: 'Curazao',
    CX: 'Isla de Navidad',
    CY: 'Chipre',
    CZ: 'República Checa',
    DE: 'Alemania',
    DJ: 'Yibuti',
    DK: 'Dinamarca',
    DM: 'Dominica',
    DO: 'República Dominicana',
    DZ: 'Argelia',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egipto',
    EH: 'Sahara Occidental',
    ER: 'Eritrea',
    ES: 'España',
    ET: 'Etiopía',
    FI: 'Finlandia',
    FJ: 'Fiyi',
    FM: 'Micronesia',
    FO: 'Islas Feroe',
    FR: 'Francia',
    GA: 'Gabón',
    GB: 'Reino Unido',
    GD: 'Granada',
    GE: 'Georgia',
    GF: 'Guayana Francesa',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Groenlandia',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadalupe',
    GQ: 'Guinea Ecuatorial',
    GR: 'Grecia',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bisáu',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HR: 'Croacia',
    HT: 'Haití',
    HU: 'Hungría',
    ID: 'Indonesia',
    IE: 'Irlanda',
    IL: 'Israel',
    IM: 'Isla de Man',
    IN: 'India',
    IO: 'Territorio Británico del Océano Índico',
    IQ: 'Irak',
    IR: 'Irán',
    IS: 'Islandia',
    IT: 'Italia',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordania',
    JP: 'Japón',
    KE: 'Kenia',
    KG: 'Kirguistán',
    KH: 'Camboya',
    KI: 'Kiribati',
    KM: 'Comoras',
    KN: 'San Cristóbal y Nieves',
    KP: 'Corea del Norte',
    KR: 'Corea del Sur',
    KW: 'Kuwait',
    KY: 'Islas Caimán',
    KZ: 'Kazajistán',
    LA: 'Laos',
    LB: 'Líbano',
    LC: 'Santa Lucía',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesoto',
    LT: 'Lituania',
    LU: 'Luxemburgo',
    LV: 'Letonia',
    LY: 'Libia',
    MA: 'Marruecos',
    MC: 'Mónaco',
    MD: 'Moldavia',
    ME: 'Montenegro',
    MF: 'San Martín',
    MG: 'Madagascar',
    MH: 'Islas Marshall',
    MK: 'Macedonia del Norte',
    ML: 'Malí',
    MM: 'Birmania',
    MN: 'Mongolia',
    MO: 'Macao',
    MP: 'Islas Marianas del Norte',
    MQ: 'Martinica',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauricio',
    MV: 'Maldivas',
    MW: 'Malaui',
    MX: 'México',
    MY: 'Malasia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'Nueva Caledonia',
    NE: 'Níger',
    NF: 'Isla Norfolk',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Países Bajos',
    NO: 'Noruega',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Nueva Zelanda',
    OM: 'Omán',
    PA: 'Panamá',
    PE: 'Perú',
    PF: 'Polinesia Francesa',
    PG: 'Papúa Nueva Guinea',
    PH: 'Filipinas',
    PK: 'Pakistán',
    PL: 'Polonia',
    PM: 'San Pedro y Miquelón',
    PN: 'Islas Pitcairn',
    PR: 'Puerto Rico',
    PS: 'Palestina',
    PT: 'Portugal',
    PW: 'Palaos',
    PY: 'Paraguay',
    QA: 'Catar',
    RE: 'Reunión',
    RO: 'Rumanía',
    RS: 'Serbia',
    RU: 'Rusia',
    RW: 'Ruanda',
    SA: 'Arabia Saudita',
    SB: 'Islas Salomón',
    SC: 'Seychelles',
    SD: 'Sudán',
    SE: 'Suecia',
    SG: 'Singapur',
    SH: 'Santa Elena',
    SI: 'Eslovenia',
    SJ: 'Svalbard y Jan Mayen',
    SK: 'Eslovaquia',
    SL: 'Sierra Leona',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Surinam',
    SS: 'Sudán del Sur',
    ST: 'Santo Tomé y Príncipe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Siria',
    SZ: 'Suazilandia',
    TC: 'Islas Turcas y Caicos',
    TD: 'Chad',
    TF: 'Territorios Australes Franceses',
    TG: 'Togo',
    TH: 'Tailandia',
    TJ: 'Tayikistán',
    TK: 'Tokelau',
    TL: 'Timor Oriental',
    TM: 'Turkmenistán',
    TN: 'Túnez',
    TO: 'Tonga',
    TR: 'Turquía',
    TT: 'Trinidad y Tobago',
    TV: 'Tuvalu',
    TZ: 'Tanzania',
    UA: 'Ucrania',
    UG: 'Uganda',
    US: 'Estados Unidos',
    UY: 'Uruguay',
    UZ: 'Uzbekistán',
    VA: 'Ciudad del Vaticano',
    VC: 'San Vicente y las Granadinas',
    VE: 'Venezuela',
    VG: 'Islas Vírgenes Británicas',
    VI: 'Islas Vírgenes de los Estados Unidos',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis y Futuna',
    WS: 'Samoa',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'Sudáfrica',
    ZM: 'Zambia',
    ZW: 'Zimbabue',
  },
  Hive_DriverPortfolioPage: {
    TITLE: 'Conductores y gestión de cartera',
    SIDEBAR_TITLE: 'Gestión de conductores',
    SEARCH_DRIVER: 'Buscar por nombre de conductor, ID de conductor o patente',
    REGISTER_DRIVERS: 'Registro de conductores en Data management',
    NO_DRIVERS_REGISTERED: 'No hay conductores registrados',
    ONCE_REGISTERED:
      'Una vez registrados, los conductores aparecerán en esta sección',
    REGISTER_DRIVERS_SHORT: 'Registrar controladores',
    DRIVER_ID: 'ID',
    DRIVER_NAME: 'Nombre',
    VEHICLE: 'Camión',
    POCS: 'PDVs en cartera',
    ACTIONS: 'Acciones',
  },
  Hive_DriverProfilePage: {
    DRIVER_DETAILS: 'Detalles del conductor',
    EDIT_BUTTON: 'Editar',
    CANCEL_BUTTON: 'Cancelar',
    SAVE_CHANGES_BUTTON: 'Guardar cambios',
    DRIVER_NAME: 'Nombre',
    DRIVER_ID: 'ID',
    DRIVER_TRUCK: 'Camión',
    DRIVER_PHONE: 'Teléfono',
    CUSTOMER_PORTFOLIO_TITLE: 'Cartera de clientes',
    TABLE_SEARCH_PLACEHOLDER: 'Buscar por nombre o ID de PDV',
    TABLE_COLUMN_POC_ID: 'ID PDV',
    TABLE_COLUMN_POC_NAME: 'Nombre del PDV',
    TABLE_COLUMN_ADDRESS: 'Dirección',
    TABLE_EMPTY_NO_POC_REGISTERED:
      'Una vez registrado, los PDV aparecerán en esta sección.',
    TABLE_EMPTY_NO_POC_FOUND:
      'No se encontraron resultados. Revise la ortografía o pruebe con diferentes palabras clave.',
    SEARCH_DRIVER: 'Buscar conductor',
    REASSIGN_POCS: 'Reasignar PDV',
    CONFIRM_REASSIGN_MODAL_TITLE: '¿Reasignar PDV?',
    CONFIRM_REASSIGN_MODAL_MESSAGE:
      'Los PDV seleccionados se reasignarán al conductor seleccionado.',
    CONFIRM_REASSIGN_MODAL_SAVE_CHANGES_BUTTON: 'Guardar cambios',
    CONFIRM_REASSIGN_MODAL_CANCEL_BUTTON: 'Volver atrás',
    CONFIRM_REASSIGN_MODAL_CONFIRM_BUTTON: 'Reasignar',
    DELETE_POC_MODAL_TITLE: '¿Eliminar PDV?',
    DELETE_POC_MODAL_MESSAGE:
      'Esto eliminará permanentemente la PDV. Esto no se puede deshacer.',
    DELETE_POC_MODAL_CONFIRM_BUTTON: 'Eliminar PDV',
    DELETE_POC_MODAL_CANCEL_BUTTON: 'Volver atrás',
    DISCARD_CHANGES_TITLE: '¿Descartar cambios?',
    DISCARD_CHANGES_DESCRIPTION:
      'Se descartarán todos los cambios no guardados.',
    DISCARD_CHANGES_CONFIRMATION: 'Descartar cambios',
    DISCARD_CHANGES_GO_BACK: 'Volver atrás',
  },
  Hive_RoutesPage: {
    TITLE: 'Rutas',
    SIDEBAR_TITLE: 'Rutas',
    CREATE_ROUTES: 'Crear rutas',
    SCHEDULED_TAB: 'Programadas',
    SCHEDULED_SEARCH: 'Buscar por conductor o ID de ruta',
  },
  Hive_CreateRoutesPage: {
    TITLE: 'Crear rutas',
    MAP_LEGEND: 'PDVs sin camión ni conductor',
  },
};

export default {
  messages: es,
  language: 'es',
};
