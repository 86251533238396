import { ThemeProvider } from '@hexa-ui/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { EnvConfig, ServicesOneProvider } from 'deliver-portal-commons';

import { messages } from '../shared/i18n';
import Router from './router/Router';

const queryClient = new QueryClient();

export default function App(envConfig: Readonly<EnvConfig>) {
  return (
    <ThemeProvider theme="force">
      <QueryClientProvider client={queryClient}>
        <ServicesOneProvider envConfig={envConfig} messages={messages}>
          <Router />
        </ServicesOneProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}
