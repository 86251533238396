import { FileStatus } from '../interfaces';
import { useToast } from './useToast';

export interface IRecommendation {
  id: string;
  status: string;
  referenceName: string;
  description: string;
  total: number;
  validated: number;
  failed: number;
  processed: number;
  previousStatus: string;
}

export const useNotificationMassApproval = () => {
  const { toast } = useToast();
  const notify = (recommendation: IRecommendation) => {
    if (!recommendation?.status || recommendation?.status === recommendation?.previousStatus)
      return;
    const { status, failed, total, validated } = recommendation;
    switch (status) {
      case FileStatus.DONE:
        if (failed > 0) {
          toast.warning({
            message: 'massApproval.toasts.issueMessage',
            attributes: { value: validated, total: total, issue: failed },
          });
        } else {
          toast.success({
            message: 'massApproval.toasts.recommendationSuccess',
            attributes: { value: total },
          });
        }
        break;
      case FileStatus.FAILED:
        toast.error({
          message: 'massApproval.toasts.errorApproved',
          attributes: { value: total },
        });

        break;
      default:
        console.warn('Unknown file status:', status);
    }
  };

  return { notify };
};
