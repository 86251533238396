import MessageMap from '../i18n';

const ptBR: MessageMap = {
  TEST: {
    TEST_TRANSLATE: 'Traduzir',
  },
  FORMAT: {
    CURRENCY: 'BRL',
  },
  SIDEBAR: {
    COMPANY_PROFILE_SETUP: 'Configuração do perfil da empresa',
    COMPANIES: 'Perfis de empresa',
    STORES: 'Lojas',
    VENDORS_GROUP: 'Sellers',
  },
  TOGGLE: {
    ACTIVE_TEXT: 'Ativo',
    INACTIVE_TEXT: 'Inativo',
  },
  COMPONENTS: {
    NOT_FOUND_HEADER_TEXT: 'Mmm… Parece que esta página sumiu',
    NOT_FOUND_SUBHEADER_TEXT: 'Parece que esta página não foi encontrada ou não existe.',
    NOT_FOUND_HOME_BTN: 'Voltar',
    ERROR_GENERIC: 'Algo deu errado',
    TOGGLE_ACTIVE_STATUS: 'Ativado',
    TOGGLE_INACTIVE_STATUS: 'Desativado',
    STEPPER_BACK_BUTTON: 'Voltar',
    STEPPER_NEXT_BUTTON: 'Próximo',
  },
  HOME: {
    TITLE: 'Configuração do perfil da empresa',
    WELCOME: 'Bem-vindo à Configuração do perfil da empresa do BEES',
    WELCOME_DESCRIPTION:
      'Descubra, acesse e gerencie todos os perfis de lojas e empresas. Verifique todos os tipos de fornecedores, parceiros e modelos de serviço.',
    GET_TOOLS: 'Conheça as ferramentas',
    GET_TOOLS_DESCRIPTION:
      'Acesso rápido a todas as ferramentas disponíveis para gerenciar parceiros de todos os modelos de negócios.',
    APP_STORES_TITLE: 'Lojas',
    APP_STORES_DESCRIPTION: 'Acesse e gerencie lojas de cada zona e suas empresas.',
    APP_COMPANIES_TITLE: 'Perfis de empresa',
    APP_COMPANIES_DESCRIPTION: 'Acesse e gerencie os perfis registrados no aplicativo.',
  },
  COMPANY_LIST: {
    TITLE: 'Perfis de empresa',
    TABLE_LEGAL_NAME: 'Razão social',
    TABLE_GOVERNMENT_ID: 'CNPJ',
    TABLE_LEGAL_NAME_AND_GOVERNMENT_ID: 'Razão social e CNPJ',
    TABLE_VENDOR_ID: 'Identificação do fornecedor',
    TABLE_TIER: 'Classe/categoria',
    TABLE_COUNTRY: 'País',
    TABLE_VENDOR_SINCE: 'Fornecedor desde',
    TABLE_SERVICE_MODEL: 'Modelo de serviço',
    SEARCH: 'Procurar por',
    SEARCH_PLACEHOLDER: 'Buscar por razão social ou CNPJ',
    VENDOR_ID_TOOLTIP: 'Número automaticamente gerado pelo nosso banco de dados',
    SEARCH_BY_LEGAL_NAME: 'Procurar por Razão social',
    SEARCH_BY_GOVERNMENT_ID: 'Procurar por CNPJ',
    ADD_COMPANY: 'Adicionar nova empresa',
    EMPTY_VENDORS: 'Não existe empresa cadastrada no momento.',
    WRONG_VENDORS_MESSAGE: 'Algo deu errado!',
    WRONG_VENDORS_SUB_MESSAGE:
      'Lamentamos, mas estamos com problemas técnicos.  Tente novamente mais tarde.',
    TABLE_CONTEXT_MENU_ADD_DDC_BTN: 'Cadastrar um novo CDD',
    VENDOR_ID_COPIED: 'ID do fornecedor copiado para a área de transferência',
    FILTER_BY_COUNTRY: 'Filtrar por país',
    CANNOT_LOAD_SUPPORTED_COUNTRIES: 'Sua conta não possui países suportados. Fale com o suporte',
    COMPANY_CREATION_COUNTRY_NOT_SUPPORTED:
      'No momento, a criação de uma empresa para {countryName} não é suportada',
    EMPTY_MESSAGE: 'Nenhuma empresa encontrada.',
    LOADING_MESSAGE: 'Carregando empresas...',
    LINES_PER_PAGE: 'Linhas por página: {pageSize}',
  },
  ADD_COMPANY: {
    TITLE: 'Adicionar empresa',
    STEP1_LABEL: 'Informações da empresa',
    STEP1_DETAIL: 'Insira os detalhes de registro.',
    STEP1_CONTENT_TITLE: 'Inserir informações da empresa',
    STEP1_CONTENT_SUBTITLE: 'Você pode editar estas informações posteriormente.',
    STEP2_LABEL: 'Modelo de serviço',
    STEP2_DETAIL: 'Selecione o modelo de serviço.',
    STEP2_CONTENT_TITLE: 'Adicionar empresa',
    STEP2_CONTENT_SUBTITLE: 'Isso irá definir como a empresa vai operar.',
    STEP2_CONTENT_TOOLTIP: 'P refere-se ao modelo de parceiro. L refere-se ao modelo logístico.',
    CANCEL_BUTTON: 'Cancelar',
    ADD_BUTTON: 'Adicionar',
    TITLE_1P_1L_SINGLE_PROVIDER: '1P (1L) - Único provedor',
    DESCRIPTION_1P_1L_SINGLE_PROVIDER: 'Produtos são vendidos e entregues pela BEES.',
    TITLE_3P_3L_SAME_STORE: '3P (3L) - Loja do BEES',
    DESCRIPTION_3P_3L_SAME_STORE: 'Produtos são vendidos e entregues pelo fornecedor.',
    TITLE_3P_DISTRIBUTOR: '3P - Distribuidor',
    DESCRIPTION_3P_DISTRIBUTOR: 'Produtos são vendidos e entregues pelo distributor terceiro.',
    TITLE_3P_3L_WHOLESALER: '3P - Atacadistas',
    DESCRIPTION_3P_3L_WHOLESALER: 'Produtos são vendidos e entregues pelo atacadista. ',
    TITLE_3P_3L_STOREFRONT: '3P (3L) - Loja da empresa parceira',
    DESCRIPTION_3P_3L_STOREFRONT:
      'Os produtos são vendidos pelo fornecedor em sua loja e entregues por eles.',
    LEGAL_NAME_LABEL: 'Razão social',
    TRADE_NAME_LABEL: 'Nome fantasia',
    GOVERNMENT_ID_LABEL: 'CNPJ',
    GOVERNMENT_ID_TOOLTIP:
      'O número de caracteres pode variar de acordo com o país de origem, não pode conter caracteres especiais.',
    TAX_ID_LABEL: 'CNPJ',
    TAX_ID_ERROR_LABEL: 'Insira um CNPJ válido. Esse campo é obrigatório.',
    ADDRESS_LABEL: 'Endereço comercial',
    CITY_LABEL: 'Cidade',
    STATE_LABEL: 'Estado',
    ZIP_CODE_LABEL: 'CEP',
    COUNTRY_LABEL: 'País',
    COMPANY_TIER_TITLE: 'Nível da empresa',
    COMPANY_TIER_DESCRIPTION: 'Selecione o nível da empresa.',
    COMPANY_TIER_DEFAULT_LABEL: 'Prata',
    COMPANY_TIER_DEFAULT_DESCRIPTION: 'Acesse apenas as informações regulares.',
    COMPANY_TIER_SILVER_LABEL: 'Prata',
    COMPANY_TIER_SILVER_DESCRIPTION: 'Acesse apenas as informações regulares.',
    COMPANY_TIER_GOLD_LABEL: 'Ouro',
    COMPANY_TIER_GOLD_DESCRIPTION: 'Acesse recursos pagos.',
    IS_MANUFACTURER_LABEL: 'A empresa é um fabricante?',
    IS_MANUFACTURER_DESCRIPTION:
      'Para lhe fornecer os melhores serviços, precisamos saber se a empresa produz bens a partir de matérias-primas.',
    IS_MANUFACTURER_OPTION_YES: 'Sim, é um fabricante',
    IS_MANUFACTURER_OPTION_NO: 'Não, não é um fabricante',
  },
  VENDOR_FORM: {
    TITLE: 'Sobre o seu negócio',
    DESCRIPTION: 'Start registering a new company by adding all the required information',
    LABEL_LEGAL_NAME: 'Razão social',
    LABEL_DISPLAY_NAME: 'Nome fantasia',
    LABEL_GOVERNMENT_ID: 'CNPJ',
    TOOLTIP_GOVERNMENT_ID:
      'O número de caracteres pode variar de acordo com o país de origem, não pode conter caracteres especiais.',
    TOOLTIP_3P3L_ID:
      '3P (3L) Fornecedores trabalhando como uma operação logística terceirizada para produtos  do BEES',
    LABEL_LEGAL_ADDRESS: 'Endereço comercial',
    LABEL_CITY: 'Cidade',
    LABEL_STATE: 'Estado',
    LABEL_ZIP_CODE: 'CEP',
    LABEL_COUNTRY: 'País',
    LABEL_SERVICE_MODEL: 'Modelo de serviço',
    REQUIRED: 'Preenchimento obrigatório.',
    GOVERNMENT_ID_DUPLICATED:
      'Este CNPJ já está cadastrado. Insira um novo CNPJ e tente novamente.',
  },
  SERVICE_MODEL: {
    SELECT_SERVICE_MODEL_TEXT: 'Selecione o tipo de modelo de serviço que você deseja criar',
    TITLE_1P_1L: '1P (1L)',
    DESCRIPTION_1P_1L: 'Vendido e entregue pelo BEES.',
    TITLE_1P_3L_REWARDS: '1P (3L) Pontos',
    DESCRIPTION_1P_3L_REWARDS:
      'Vendido pelo BEES a partir de pontos de resgate e entregue por um fornecedor.',
    TITLE_3P_3L_SAME_STORE: 'Empresa Core/PRO - 3P (3L) Loja do Bees',
    DESCRIPTION_3P_3L_SAME_STORE: 'Vendido pelo app do BEES e entregue por uma empresa parceira.',
    TITLE_3P_3L_STOREFRONT: 'Empresa DTaaS - 3P (3L) Loja Exclusiva',
    DESCRIPTION_3P_3L_STOREFRONT: 'Vendido e entregue por uma empresa parceira.',
    TITLE_3P_1L: '3P (1L)',
    DESCRIPTION_3P_1L: 'Vendido por uma empresa parceira e entregue pelo BEES.',
  },
  ACCESSES: {
    EDITOR: 'Editor',
    ADMIN: '{admin}',
    ORDER_MANAGER: 'Order Manager',
    PRODUCT_EDITOR: 'Product Editor',
    VIEWER: 'Visualizador',
  },
  VENDOR: {
    VENDOR_ID:
      'A identificação do fornecedor é um número gerado automaticamente na nossa base de dados.',
  },
  PAGINATE: {
    ROWS_PER_PAGE: 'Linhas por página',
  },
  VENDOR_SERVICE: {
    ERROR_LOAD_ALL: 'Não foi possível carregar todas as empresas.',
    CREATE_VENDOR_SUCCESS: 'Uma nova empresa acabou de ser adicionada',
    CREATE_VENDOR_ERROR: 'Algo deu errado',
    CREATE_VENDOR_ERROR_TITLE: 'Algo deu errado',
    CREATE_VENDOR_ERROR_DESCRIPTION: 'Por favor, tente novamente.',
  },
  USER_SERVICE: {
    ERROR_LOAD_ALL: 'Não foi possível carregar todos os usuários.',
    CREATE_USER_SUCCESS: 'Um novo usuário foi adicionado',
    CREATE_USER_FAIL: 'Não é possível adicionar um novo usuário',
    CREATE_USER_ERROR_TITLE: 'Algo deu errado',
    CREATE_USER_ERROR_DESCRIPTION: 'Por favor, tente novamente.',
    RESEND_INVITE_SUCCESS: 'Convite enviado com sucesso',
    RESEND_INVITE_FAIL: 'Houve um problema ao reenviar o convite, tente novamente',
    BLOCK_USER_SUCCESS: 'Usuário desativado com sucesso',
    BLOCK_USER_FAIL: 'Algo deu errado ao inativar o usuário',
    UNBLOCK_USER_SUCCESS: 'Usuário ativado com sucesso',
    UNBLOCK_USER_FAIL: 'Algo deu errado ao ativar o usuário',
  },
  MODAL: {
    BACK: 'Voltar',
    CANCEL: 'Cancelar',
    SAVE: 'Salvar',
    CLOSE: 'Fechar',
    FINISH: 'Finalizar',
    GO_BACK: 'Voltar',
    NEXT: 'Próximo',
    CONFIRM: 'Confirmar',
    TRY_AGAIN_FAILED: 'Tente novamente',
  },
  COMPANY_DETAILS: {
    TITLE: 'Informações gerais',
    IMAGE_TITLE: 'Logo da empresa',
    IMAGE_LABEL: 'Arraste e solte ou faça upload do logo da sua empresa.',
    IMAGE_UNAUTHORIZED_LABEL: 'Você não tem permissão para fazer upload do logo da empresa.',
    IMAGE_ERROR_LOADING: 'Erro ao carregar o logo da empresa.',
    IMAGE_NOT_AVAILABLE: 'Imagem não disponível',
    IMAGE_DIMENSIONS_ERROR: 'As dimensões do arquivo são muito grandes.',
    IMAGE_TYPE_ERROR: 'Tipo de arquivo não permitido.',
    IMAGE_SIZE_ERROR: 'O tamanho do arquivo é muito grande.',
    UPLOAD_IMAGE_BTN: 'Carregar imagem',
    IMAGE_INFO: 'Suportamos os formatos JPG e PNG. Tamanho máximo de 1MB e 300 x 300px.',
    LABEL_VENDOR_ID: 'Identificação do fornecedor',
    LABEL_VENDOR_ID_TOOLTIP:
      'A identificação do fornecedor é um número gerado automaticamente na nossa base de dados.',
    LABEL_COUNTRY: 'País',
    LABEL_LEGAL_NAME: 'Razão social',
    LABEL_DISPLAY_NAME: 'Nome fantasia',
    LABEL_GOV_ID: 'CNPJ',
    LABEL_TAX_ID: 'CNPJ',
    LABEL_ADDRESS: 'Endereço',
    LABEL_TIER: 'Nível da empresa',
    LABEL_IS_MANUFACTURER: 'É um fabricante?',
    OPTION_IS_MANUFACTURER_YES: 'Sim',
    OPTION_IS_MANUFACTURER_NO: 'Não',
    LABEL_CITY: 'Cidade',
    LABEL_STATE: 'Estado',
    LABEL_ZIP: 'CEP',
    LABEL_SRV_MODEL: 'Modelo de serviço',
    LABEL_SINCE: 'Fornecedor desde',
    LABEL_DETAILS_TAB: 'Detalhes',
    EDIT_BUTTON: 'Editar',
    DONE_EDITING_BUTTON: 'Finalizar edição',
    CANCEL_BUTTON: 'Cancelar',
    SAVE_CHANGES_BUTTON: 'Salvar alterações',
    LAST_UPDATE: 'Última atualização:',
    LOAD_VENDOR_ERROR_DEFAULT: 'Algo deu errado',
    UPDATE_VENDOR_SUCCESS_DEFAULT: 'Empresa editada com sucesso',
    UPDATE_VENDOR_ERROR_DEFAULT: 'Falha ao atualizar as informações da empresa',
    CANCELLATION_MODAL_TITLE: 'Tem certeza de que deseja continuar?',
    CANCELLATION_MODAL_CONTENT:
      'Há alterações não salvas que serão perdidas. Se continuar, não poderá desfazer esta ação.',
    CANCELLATION_MODAL_KEEP_EDITING_BTN: 'Não, continuar editando',
    CANCELLATION_MODAL_PROCEED_BUTTON: 'Sim, continuar',
    BTN_SAVE_CHANGES: 'Salvar alterações',
    BTN_CANCEL: 'Cancelar',
    EDIT_STORES: 'Editar lojas',
    NO_ASSOCIATED_STORE: 'Nenhuma loja associada',
    ASSOCIATED_STORE_LABEL: 'Loja associada',
    ASSOCIATE_MODAL_TITLE: 'Associar empresa a diferentes lojas',
    ASSOCIATE_MODAL_SUBTITLE: 'Associe a empresa {vendorName} a diferentes lojas selecionando-as.',
    CONFIRM_MODAL_TITLE: 'Confirmar associação?',
    CONFIRM_MODAL_PROCESSING_TITLE: 'Associação em andamento...',
    CONFIRM_MODAL_PROCESSED_TITLE: 'Processamento concluído',
    CONFIRM_MODAL_DESCRIPTION:
      'Você está prestes a associar a empresa {vendorName} às lojas selecionadas.',
    CONFIRM_MODAL_PROCESSING_DESCRIPTION:
      'Assim que o processo for concluído, você pode fechar este modal ou tentar novamente.',
    CONFIRM_MODAL_ALL_FAILED_DESCRIPTION:
      'A associação terminou. Agora você pode fechar esta janela ou repetir o processo.',
    CONFIRM_MODAL_PROCESSED_DESCRIPTION:
      'O processo de associação foi concluído. Agora você pode fechar esta janela ou tentar novamente.',
    NO_STORES_SELECTED: 'Nenhuma loja selecionada.',
    STORES_TO_ASSOCIATE: 'Lojas para associar',
    STORES_TO_DISSOCIATE: 'Lojas para dissociar',
    STORE_SUCCESS_STATUS: 'Sucesso',
    STORE_FAILED_STATUS: 'Falha',
    SUCCESS_NOTIFY_MESSAGE: 'A empresa {company} foi atualizada com sucesso.',
    SUCCESS_NOTIFY_BUTTON: 'Ir para a listagem de lojas',
    NO_STORES_AVAILABLE_PLACEHOLDER: 'Não há loja disponível para associar',
  },
  ITEMS_TAB: {
    LABEL_ITEMS_TAB: 'Itens',
    LABEL_ITEMS_TITLE: 'Gerenciamento de itens',
    PAGE_CARD_MANUFACTURER_ID: 'ID do fabricante',
    SKU_TITLE: 'SKU',
    REMOVE_BUTTON: 'Remover',
    PUBLISH_BUTTON: 'Publicar',
    TOAST_MESSAGE_SUCCESS: 'SKUs adicionados com sucesso',
    TOAST_MESSAGE_ERROR: 'Algo deu errado!',
  },
  COMPANY_SETTINGS_TAB: {
    LABEL_COMPANY_SETTINGS_TAB: 'Configurações',
  },
  DISTRIBUTION_CENTER_TAB: {
    TITLE: 'Centros de distribuição',
    LABEL_DISTRIBUTION_CENTER_TAB: 'Centros de distribuição',
    TABLE_DISTRIBUTION_CENTER: 'Centro de distribuição',
    TABLE_COVERAGE: 'Cobertura',
    TABLE_DELIVERY_WINDOW: 'Janela de Entrega',
    EMPTY_EMAIL_LIST: 'Não há centros de distribuição no momento.',
    ADD_DDC_BUTTON_LABEL: 'CADASTRAR CDD',
    ERROR_TITLE: 'Algo deu errado',
    ERROR_CONTENT: 'Lamentamos, mas estamos com problemas técnicos.  Tente novamente mais tarde.',
    RANGES: 'gamas',
    BUSINESS_DAYS: 'dias úteis',
    TO: 'a ',
    AREAS: 'áreas',
    TABLE_CONTEXT_MENU_EDIT_BTN: 'Editar',
    TABLE_CONTEXT_MENU_DELETE_BTN: 'Deletar',
    CUSTOM_AREA: 'custom area',
    CUSTOM_AREAS: 'custom areas',
    DELETE_MODAL_TITLE: 'Delete distribution center',
    DELETE_MODAL_CANCEL: 'Cancel',
    DELETE_MODAL_DESCRIPTION: "Are you sure you want to delete ''{DCName}'' distribution center?",
    DELETE_MODAL_SUCCESS: 'Distribution center successfully deleted.',
    DELETE_MODAL_ERROR:
      "Distribution center couldn't be deleted due to a system error. Try again later.",
  },
  COMPANY_DETAILS_MODAL_FORM: {
    ADD_COMPANY_DETAILS_MODAL_TITLE: 'Adicionar Empresa',
    COMPANY_DETAILS_MODAL_TITLE: 'Editar informação do fornecedor',
    COMPANY_DETAILS_MODAL_SUB_TITLE:
      'Preencha o formulário abaixo para cadastrar uma empresa. Depois que ela for cadastrada, você poderá adicionar membros.',
    LABEL_COUNTRY: 'País',
    LABEL_LEGAL_NAME: 'Razão social',
    LABEL_DISPLAY_NAME: 'Nome fantasia',
    LABEL_GOV_ID: 'CNPJ',
    GOV_ID_TOOLTIP:
      'O número de caracteres pode variar de acordo com o país de origem, não pode conter caracteres especiais.',
    LABEL_LEGAL_ADDRESS: 'Endereço comercial',
    LABEL_CITY: 'Cidade',
    LABEL_STATE: 'Estado',
    LABEL_ZIP: 'CEP',
    LABEL_SRV_MODEL: 'Modelo de serviço',
    REQUIRED_ERROR_MESSAGE: 'Preenchimento obrigatório',
    SUBMIT_BUTTON: 'Salvar',
    ADD_COMPANY_BUTTON: 'Adicionar Empresa',
    ADD_COMPANY_CANCEL_BUTTON: 'Cancelar',
    ERROR_MODAL_TITLE: 'Algo deu errado',
    ERROR_MODAL_SUB_TITLE:
      'Lamentamos, mas estamos com problemas técnicos.  Tente novamente mais tarde',
    GO_BACK_BUTTON_LABEL: 'Voltar',
    CLOSE_BUTTON_LABEL: 'Fechar',
  },
  GOV_ID_COMPONENT: {
    LABEL: 'CNPJ',
    INVALID: 'CNPJ Inválido',
  },
  ADD_USER_MODAL: {
    SUBMIT_BUTTON_TITLE: 'Adicionar',
    CANCEL_BUTTON_TITLE: 'Cancelar',
    CLOSE_BUTTON_TITLE: 'Fechar',
    MODAL_SUB_TITLE:
      'Os e-mails cadastrados nesta etapa receberão um link para acessar o portal do Partner.',
    MODAL_TITLE: 'Adicionar usuários',
    ERROR_MODAL_TITLE: 'Algo deu errado',
    ERROR_MODAL_SUB_TITLE:
      'Lamentamos, mas estamos com problemas técnicos.  Tente novamente mais tarde',
    GO_BACK_BUTTON_LABEL: 'Voltar',
    CLOSE_BUTTON_LABEL: 'Fechar',
    REQUIRED_ERROR_MESSAGE: 'Preenchimento obrigatório',
    INVALID_EMAIL_ERROR_MESSAGE: 'E-mail inválido ou incorreto',
    DUPLICATED_EMAIL_ERROR_MESSAGE: 'E-mail já existe',
    DUPLICATED_EMAIL_INPUT_ERROR_MESSAGE: 'Este e-mail já está sendo usado em outro campo',
    ADD_USER_ERROR_DEFAULT: 'Algo deu errado',
  },
  FORMIK_USER_LIST: {
    EMAIL: 'E-mail',
    ACCESS: 'Acessar como',
  },
  COMPANY_USERS: {
    TITLE: 'Gerenciamento de usuários',
    LABEL_USERS_TAB: 'Usuários',
    TABLE_NAME: 'Nome',
    TABLE_EMAIL: 'E-mail',
    TABLE_ACCESS: 'Acesso',
    TABLE_STATUS: 'Status',
    EMPTY_EMAIL_LIST: 'Não existe usuário cadastrado no momento.',
    BUTTON_LABEL: 'Adicionar usuários',
    RESEND_INVITE_BUTTON: 'Reenviar convite',
    BLOCK_USER_BUTTON: 'Desativar usuário',
    UNBLOCK_USER_BUTTON: 'Ativar usuário',
  },
  BLOCK_UNBLOCK_USER_MODAL: {
    BLOCK_BUTTON: 'DESATIVAR',
    UNBLOCK_BUTTON: 'ATIVAR',
    BLOCK_TITLE: 'Desativar usuário',
    UNBLOCK_TITLE: 'Ativar usuário',
    CANCEL: 'CANCELAR',
    BLOCK_TEXT:
      'Você está prestes a desativar está conta. O usuário não será mais capaz de acessar a plataforma após isso. Você poderá ativar a conta novamente quando desejar',
    UNBLOCK_TEXT: 'Você está prestes a ativar o usuário. Tem certeza que deseja continuar?',
  },
  USER_STATUS: {
    PENDING: 'Pendente',
    BLOCKED: 'Inativo',
    ACTIVE: 'Ativo',
  },
  ACCOUNT_TIER: {
    LABEL_ACCOUNT_TIER: 'Nível da conta',
    DESCRIPTION_ACCOUNT_TIER_CREATE: 'Selecione o tipo de conta que você está criando.',
    DESCRIPTION_ACCOUNT_TIER_EDIT: 'Selecione o tipo de conta.',
    LABEL_TIER_SILVER: 'Prata',
    DESCRIPTION_TIER_SILVER: 'Acesse apenas as informações regulares.',
    LABEL_TIER_DEFAULT: 'Prata',
    DESCRIPTION_TIER_DEFAULT: 'Acesse apenas as informações regulares.',
    LABEL_TIER_GOLD: 'Ouro',
    DESCRIPTION_TIER_GOLD: 'Acesse recursos pagos.',
    LABEL_CHANGE_TIER: 'Alterar nível da conta',
    LABEL_EDIT_TIER: 'Editar nível',
    FETCH_AVAILABLE_TIERS_ERROR:
      'Não foi possível carregar as informações do país. Por favor, selecione-o novamente.',
    FETCH_AVAILABLE_TIERS_SOMETHING_WENT_WRONG:
      'Algo deu errado ao tentar carregar as informações do país.',
    CHANGE_TIER_SUCCESS_MESSAGE: 'Tier da conta atualizada com sucesso.',
    CHANGE_TIER_ERROR_MESSAGE:
      'Não foi possível atualizar o tier da conta. Tente novamente mais tarde.',
  },
  COMPANY_SETTINGS: {
    SAVE_CHANGES: 'Save changes',
    CANCEL: 'Cancel',
    EMPTIES_CONFIGURATION_TITLE: 'Empties configuration',
    EMPTIES_DESCRIPTION: 'Enable cart with empties only?',
    EMPTIES_DESCRIPTION_TOOLTIP: 'Empties are returnable products that can be reused or recycled.',
    PAY_AND_REDEEM_TITLE: 'Pay and redeem',
    PAY_AND_REDEEM_SUBTITLE: 'Select the partners payment method.',
    PAYMENT_METHOD_INPUT_LABEL: 'Minimum order amount',
    BANK_SLIP_PAYMENT_METHOD_INPUT_LABEL: 'Days to expire',
    CASH_PAYMENT_METHOD_NAME: 'Cash',
    BANK_SLIP_PAYMENT_METHOD_NAME: 'Bank slip',
    BANK_DEPOSIT_PAYMENT_METHOD_NAME: 'Bank deposit',
    BANK_TRANSFER_PAYMENT_METHOD_NAME: 'Bank transfer',
    CASH_DEPOSIT_PAYMENT_METHOD_NAME: 'Cash deposit',
    EFT_PAYMENT_METHOD_NAME: 'EFT (Electronic funds transfer)',
    BANK_DEPOSIT_PAYMENT_METHOD_DESCRIPTION:
      'This payment method allows the customer to pay for the order via bank deposit.',
    CASH_PAYMENT_METHOD_DESCRIPTION:
      'This payment method allows the customer to pay for the order only in cash upon delivery',
    BANK_SLIP_PAYMENT_METHOD_DESCRIPTION:
      'This payment method allows the customer to pay for the order with a bank slip',
    ORDER_NOTIFICATIONS_TITLE: 'Order notifications',
    ORDER_NOTIFICATIONS_SUBTITLE: 'Select the recipients.',
    ORDER_NOTIFICATIONS_NO_SALES_REP_LABEL: 'No sales reps',
    ORDER_NOTIFICATIONS_PRIMARY_SALES_REP_LABEL: 'Primary sales reps',
    ORDER_NOTIFICATIONS_ALL_SALES_REPS_LABEL: 'All sales reps',
    ORDER_NOTIFICATIONS_ALL_SALES_REPS_SUPERVISOR_LABEL: 'All sales reps + team leader/manager',
    ORDER_NOTIFICATIONS_EMAIL_LIST: 'Email list',
    ORDER_NOTIFICATIONS_EMAIL_LIST_SUBTITLE: 'Use commas to separate the emails',
    ORDER_NOTIFICATIONS_ALERT_WARNING:
      'Sales reps will only receive order notifications if their emails are previously registered at BEES. Please reach out to BEES Support if any questions.',
    REDEEM_DESCRIPTION:
      'Will they participate in Club B and allow orders to be fully redeemed with points?',
    REDEEM_DESCRIPTION_TOOLTIP:
      'Allowing customers to redeem products by paying fully with points, no minimum checkout amount is needed.',
    REDEEM_OPTION_YES: 'Yes',
    REDEEM_OPTION_NO: 'No',
    EMPTIES_OPTION_YES: 'Yes',
    EMPTIES_OPTION_NO: 'No',
    MINIMUM_ORDER_LABEL: 'Minimum order amount',
    MAXIMUM_ORDER_LABEL: 'Maximum order amount',
    SAVE_COMPANY_SETTINGS_SUCCESSFULLY: 'Changes saved successfully',
    SAVE_COMPANY_SETTINGS_FAILURE: 'Unable to save changes.',
    DUPLICATED_PAYMENT_METHOD: "There's already a payment method with the same name added.",
    PAYMENT_MANAGER_BTN: 'Manage payment methods',
    PAYMENT_MANAGER_MODAL_TITLE: 'Manage payment methods',
    PAYMENT_MANAGER_MODAL_DESCRIPTION: 'Select an existing payment method or add a new one.',
    PAYMENT_MANAGER_MODAL_EXISTING_LABEL: 'Select existing payment method',
    PAYMENT_MANAGER_MODAL_SELECT_PLACEHOLDER: 'Select',
    PAYMENT_MANAGER_MODAL_NONE_AVAILABLE_METHODS: 'No payment method available',
    PAYMENT_MANAGER_MODAL_CREATE_CUSTOM_LABEL: 'Create custom payment method',
    PAYMENT_MANAGER_MODAL_CREATE_CUSTOM_BTN: 'Add',
    PAYMENT_MANAGER_MODAL_CONFIRM: 'Confirm',
    PAYMENT_MANAGER_MODAL_CANCEL: 'Cancel',
    CUSTOM_PAYMENT_MODAL_TITLE: 'Add new payment method',
    CUSTOM_PAYMENT_MODAL_DESCRIPTION: 'Define details for the new payment method.',
    CUSTOM_PAYMENT_MODAL_TOOLTIP:
      'This language is automatically set according to the partner zone.',
    CUSTOM_PAYMENT_MODAL_LABEL: 'Payment method name in {language}',
    CUSTOM_PAYMENT_MODAL_PLACEHOLDER: 'Example: {example}',
    CUSTOM_PAYMENT_MODAL_CONFIRM: 'Confirm',
    CUSTOM_PAYMENT_MODAL_BACK: 'Go back',
    INPUT_ENGLISH_LABEL: 'English',
    INPUT_ENGLISH_PLACEHOLDER: 'Cash',
    INPUT_PORTUGUESE_LABEL: 'Portuguese',
    INPUT_PORTUGUESE_PLACEHOLDER: 'Dinheiro',
    INPUT_SPANISH_LABEL: 'Spanish',
    INPUT_SPANISH_PLACEHOLDER: 'Efectivo',
    INPUT_CHINESE_LABEL: 'Chinese',
    INPUT_CHINESE_PLACEHOLDER: '现金',
    INPUT_FRENCH_LABEL: 'French',
    INPUT_FRENCH_PLACEHOLDER: 'Espèces',
    CANCELLATION_MODAL_KEEP_EDITING_BTN: 'No, keep editing',
    CANCELLATION_MODAL_PROCEED_BUTTON: 'Yes, proceed',
    CANCELLATION_MODAL_TITLE: 'Are you sure you want to continue?',
    CANCELLATION_MODAL_CONTENT:
      'There are unsaved changes that will be lost. If you proceed, you cannot undo this action.',
  },
  PAYMENT_AND_ORDERS: {
    TAB_TITLE: `{country, select,
    US {Pedidos}
    other {Pagamento e pedidos}
    }`,
    PAYMENT_METHODS_TITLE: 'Métodos de pagamento',
    PAYMENT_METHODS_SUBTITLE: 'Selecione o método de pagamento do parceiro.',
    BANK_SLIP_PAYMENT_METHOD_INPUT_LABEL: 'Dias para o vencimento',
    PAYMENT_MANAGER_BTN: 'Gerenciar métodos de pagamento',
    PAYMENT_MANAGER_MODAL_TITLE: 'Gerenciar métodos de pagamento',
    PAYMENT_MANAGER_MODAL_DESCRIPTION:
      'Selecione um método de pagamento existente ou adicione um novo.',
    PAYMENT_MANAGER_MODAL_EXISTING_LABEL: 'Selecione um método de pagamento existente',
    PAYMENT_MANAGER_MODAL_SELECT_PLACEHOLDER: 'Selecionar',
    PAYMENT_MANAGER_MODAL_NONE_AVAILABLE_METHODS: 'Nenhum método de pagamento disponível',
    PAYMENT_MANAGER_MODAL_CREATE_CUSTOM_LABEL: 'Criar método de pagamento personalizado',
    PAYMENT_MANAGER_MODAL_CREATE_CUSTOM_BTN: 'Adicionar',
    PAYMENT_MANAGER_MODAL_CONFIRM: 'Confirmar',
    PAYMENT_MANAGER_MODAL_CANCEL: 'Cancelar',
    CUSTOM_PAYMENT_MODAL_TITLE: 'Adicionar novo método de pagamento',
    CUSTOM_PAYMENT_MODAL_DESCRIPTION: 'Defina os detalhes para o novo método de pagamento.',
    CUSTOM_PAYMENT_MODAL_TOOLTIP:
      'Este idioma é automaticamente definido de acordo com a zona do parceiro.',
    CUSTOM_PAYMENT_MODAL_LABEL: 'Nome do método de pagamento em {language}',
    CUSTOM_PAYMENT_MODAL_PLACEHOLDER: 'Exemplo: {example}',
    CUSTOM_PAYMENT_MODAL_CONFIRM: 'Confirmar',
    CUSTOM_PAYMENT_MODAL_BACK: 'Voltar',
    INPUT_ENGLISH_LABEL: 'Inglês',
    INPUT_ENGLISH_PLACEHOLDER: 'Cash',
    INPUT_PORTUGUESE_LABEL: 'Português',
    INPUT_PORTUGUESE_PLACEHOLDER: 'Dinheiro',
    INPUT_SPANISH_LABEL: 'Espanhol',
    INPUT_SPANISH_PLACEHOLDER: 'Efectivo',
    INPUT_CHINESE_LABEL: 'Chinês',
    INPUT_CHINESE_PLACEHOLDER: '现金',
    INPUT_FRENCH_LABEL: 'Francês',
    INPUT_FRENCH_PLACEHOLDER: 'Espèces',
    INPUT_KOREAN_LABEL: 'Coreano',
    INPUT_KOREAN_PLACEHOLDER: '현금',
    INPUT_FILIPINO_LABEL: 'Filipino',
    INPUT_FILIPINO_PLACEHOLDER: 'Pera',
    INPUT_INDONESIAN_LABEL: 'Indonésio',
    INPUT_INDONESIAN_PLACEHOLDER: 'Tunai',
    INPUT_DUTCH_LABEL: 'Holandês',
    INPUT_DUTCH_PLACEHOLDER: 'Contant',
    INPUT_GERMAN_LABEL: 'Alemão',
    INPUT_GERMAN_PLACEHOLDER: 'Bargeld',
    ORDER_CONFIGURATIONS_TITLE: 'Configurações de pedidos',
    MINIMUM_ORDER_LABEL: 'Mínimo por pedido',
    MINIMUM_ORDER_SUBTITLE: 'Defina um mínimo para a quantidade ou valor total do pedido.',
    MINIMUM_ORDER_INPUT_LABEL: 'Total mínimo do pedido',
    MAXIMUM_ORDER_LABEL: 'Máximo por pedido',
    MAXIMUM_ORDER_SUBTITLE: 'Defina um máximo para a quantidade ou valor total do pedido.',
    MAXIMUM_ORDER_INPUT_LABEL: 'Total máximo do pedido',
    MINIMUM_MAXIMUM_OPTIONAL: '(opcional)',
    MINIMUM_MAXIMUM_PRODUCT_QUANTITY: 'Quantidade de produtos',
    MINIMUM_MAXIMUM_ORDER_TOTAL: 'Total do pedido',
    ORDER_NOTIFICATIONS_TITLE: 'Notificações de pedido',
    ORDER_NOTIFICATIONS_SUBTITLE: 'Selecione os destinatários',
    ORDER_NOTIFICATIONS_NO_SALES_REP: 'Nenhum representante de vendas',
    ORDER_NOTIFICATIONS_PRIMARY_SALES_REP: 'Representante de vendas principal',
    ORDER_NOTIFICATIONS_ALL_SALES_REPS: 'Todos os representantes de vendas',
    ORDER_NOTIFICATIONS_ALL_SALES_REPS_SUPERVISOR:
      'Todos os representantes de vendas + líder/gerente de equipe',
    ORDER_NOTIFICATIONS_ALERT_WARNING:
      'Os representantes de vendas só receberão notificações de pedidos se os seus e-mails forem registrados com antecedência no BEES. Entre em contato com o suporte da BEES se tiver alguma dúvida.',
    ORDER_NOTIFICATIONS_EMAIL_LIST: 'Lista de e-mails',
    ORDER_NOTIFICATIONS_EMAIL_LIST_SUBTITLE: 'Use vírgulas para separar os e-mails',
    REDEEM_TITLE: 'Participação no Clube B',
    REDEEM_DESCRIPTION:
      'Eles participarão do Clube B? Permitir que os pedidos sejam totalmente resgatados com pontos?',
    REDEEM_DESCRIPTION_TOOLTIP:
      'Ao permitir que clientes resgatem produtos pagando integralmente com pontos, não é necessário um valor mínimo para fechar o pedido.',
    EMPTIES_CONFIGURATION_TITLE: 'Configuração de vasilhames',
    EMPTIES_DESCRIPTION: 'Habilitar carrinho apenas com vasilhames?',
    EMPTIES_DESCRIPTION_TOOLTIP:
      'Isso permite que os PDVs devolvam os vasilhames sem comprar nada.',
    OPTION_YES: 'Sim',
    OPTION_NO: 'Não',
  },
  RECOMMENDATION: {
    TAB_LABEL: `Recomendações{country, select,
      BR { e clientes}
      other {}
    }`,
    PRODUCT_RECOMMENDATIONS: 'Recomendações de produtos',
    SUGGESTED_ORDER_LABEL: 'Carrossel de pedidos sugeridos',
    SUGGESTED_ORDER_DESCRIPTION:
      'Apresenta produtos na página inicial do aplicativo BEES para aumentar a visibilidade.',
    SUGGESTED_ORDER_PERSONALIZED_LABEL: 'Personalizado',
    SUGGESTED_ORDER_PERSONALIZED_DESCRIPTION: 'Sugere produtos com base em pedidos anteriores.',
    SUGGESTED_ORDER_DEFAULT_LABEL: 'Padrão',
    SUGGESTED_ORDER_DEFAULT_DESCRIPTION: 'Sugere uma lista fixa de produtos.',
    INVENTORY_LABEL: 'Soluções de estoque',
    INVENTORY_DESCRIPTION: 'Opções para gerenciar o estoque.',
    INVENTORY_LIMITED_LABEL: 'Limitado',
    INVENTORY_LIMITED_DESCRIPTION: 'O pedido do cliente será limitado pela quantidade do estoque.',
    INVENTORY_BACKORDER_LABEL: 'Pedido em atraso',
    INVENTORY_BACKORDER_DESCRIPTION:
      'Permite que o cliente continue com um pedido se o estoque for zero. O cliente será notificado sobre as alterações na entrega.',
    UPSELLING_LABEL: 'Pop-up de upselling',
    UPSELLING_DESCRIPTION: 'Recomenda diferentes produtos para aumentar as vendas.',
    REPLACEMENT_LABEL: 'Substituição sem estoque',
    REPLACEMENT_DESCRIPTION:
      'Se o estoque for zero, produtos semelhantes serão sugeridos como substituições.',
  },
  FULFILLMENT: {
    ALLOWED_CUSTOMERS: 'Clientes permitidos',
    POC_TYPES_LABEL: 'Selecione quais tipos de PDVs terão permissão para fazer pedidos.',
    POC_TYPES_CNPJ_LABEL: 'Somente CNPJs',
    POC_TYPES_CNPJ_DESCRIPTION: 'Apenas PDVs registrados com um CNPJ poderão fazer pedidos.',
    POC_TYPES_CNPJ_AND_CPF_LABEL: 'CNPJs e CPFs',
    POC_TYPES_CNPJ_AND_CPF_DESCRIPTION:
      'PDVs registrados com um CPF ou CNPJ poderão fazer pedidos.',
  },
  FORM_MESSAGES: {
    ERROR_REQUIRED_FIELD: 'Este campo é obrigatório',
    ERROR_VALID_NUMBER: 'Por favor, insira um número válido',
    ERROR_BIGGER_THAN_ZERO: 'O valor deve ser maior que zero',
    ERROR_INTEGER_NUMBER: 'O valor deve ser um número inteiro',
    ERROR_LESS_THAN: 'O valor deve ser menor que {value}',
    ERROR_MAXIMUM_GREATER_THAN_MINIMUM:
      'O valor máximo do pedido deve ser maior que o valor mínimo do pedido',
  },
  SUCCESS_MESSAGES: {
    CHANGES_SAVED_SUCCESSFULLY: 'Alterações salvas com sucesso.',
    COMPANY_ADDED_SUCCESSFULLY: 'Empresa adicionada com sucesso.',
  },
  ERROR_MESSAGES: {
    UNABLE_TO_SAVE_CHANGES: 'Não foi possível salvar as alterações.',
    DUPLICATED_PAYMENT_METHOD: 'Já existe um método de pagamento com o mesmo nome adicionado.',
  },
};

export default ptBR;
