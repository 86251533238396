import { useEnvContext } from '@/envProvider';
import forge from 'node-forge';
import { bytesSize, cipherAlgorithm, ivEnd, ivStart, saltPrefix } from './constants';
import { UseCryptoHelpersReturn } from './useCryptoHelpers.types';

export const useCryptoHelpers = (): UseCryptoHelpersReturn => {
  const { cryptoSecret } = useEnvContext();
  return { encryptAES: encryptAES(cryptoSecret), decryptAES: decryptAES(cryptoSecret) };
};

const encryptAES =
  (secret: string) =>
  (plainText: string): string => {
    if (!secret) {
      throw new Error('Failed to encrypt - Crypto secret is empty');
    }
    const encodedText = encodeURI(plainText);
    const salt = forge.random.getBytesSync(bytesSize);
    const password = secret + salt;
    const key = forge.pkcs5.pbkdf2(password, salt, 1000, 32);
    const iv = forge.random.getBytesSync(16);
    const cipher = forge.cipher.createCipher(cipherAlgorithm, key);
    cipher.start({ iv: iv });
    cipher.update(forge.util.createBuffer(encodedText));
    cipher.finish();
    const encrypted = cipher.output;
    return forge.util.bytesToHex(saltPrefix + salt + iv + encrypted.bytes());
  };

const decryptAES =
  (secret: string) =>
  (encryptedText: string): string => {
    if (!secret) {
      throw new Error('Failed to decrypt - Crypto secret is empty');
    }
    const cypher = forge.util.hexToBytes(encryptedText);
    const salt = cypher.slice(ivStart, ivEnd);
    const password = secret + salt;
    const key = forge.pkcs5.pbkdf2(password, salt, 1000, 32);
    const iv = cypher.slice(ivEnd, ivEnd + 16);
    const encrypted = cypher.slice(ivEnd + 16);
    const decipher = forge.cipher.createDecipher(cipherAlgorithm, key);
    decipher.start({ iv: iv });
    decipher.update(forge.util.createBuffer(encrypted));
    decipher.finish();
    const decodedText = decodeURI(decipher.output.toString());
    return decodedText;
  };
