import MessageMap from "../domains/MessageMap";

export const translationID: MessageMap = {
  FORMAT: {
    formattedDate: "{{date, dd/MM/yyyy}}",
    FORMAT_DATE_SHORT: "dd/MM/yyyy",
    FORMAT_DATE_SHORT_SEGMENT: "d/M/yyyy",
    FORMAT_DATE_SHORT_FILE: "dd-M-yyyy",
    FORMAT_DATE_LONG_FILE: "dd-MM-yyyy",
    DATE_FORMAT_TABLE_RENDER: "{{date, d MMMM yyyy}}",
    DATE_FORMAT_TABLE: "d MMMM yyyy",
  },
  ADMIN: {
    TITLE: "Rewards - Admin",
    PANEL_HEADER: "Portal Admin",
    LOADING: "Memuat...",
    VIEW: "Lihat",
    EDIT: "Edit",
    REMOVE: "Hapus",
    DELETE: "Hapus",
    DUPLICATE: "Duplikasi",
    REVIEW_ID: "Tinjau ID",
    ACTIONS: "Tindakan",
    NAME: "Nama",
    SKU: "SKU",
    SKU_ID: "ID SKU",
    SKU_ID_PLACEHOLDER: "Ketik ID SKU di sini...",
    QUANTITY: "Kuantitas",
    IMAGE_SUPPORTED_MAX_1MB:
      "Ekstensi file PNG dan JPG didukung. Ukuran file maksimum 1MB.",
    TABLE_PAGINATION_INITIAL_COUNT:
      "Menampilkan {{showingCount}} dari {{totalRows}}",
    TABLE_PAGINATION_COUNT:
      "Menampilkan {{showingCount}} hingga {{toCount}} dari {{totalRows}}",
    SIGN_OUT: "Keluar",
    LOGOUT: "Logout",
    LOGOUT_DIALOG:
      "Apakah Anda yakin ingin keluar dari BEES GROW ADMIN PORTAL?",
    STEP_1: "Langkah 1",
  },
  LOADING: {
    BEELOADING_TEXT: "Memproses…",
  },
  BUTTON: {
    UPDATE: "Perbarui",
    CONTINUE: "Lanjutkan",
    CANCEL: "Batal",
    CLOSE: "Tutup",
    CONFIRM: "Konfirmasi",
    SAVE: "Simpan",
    SAVE_DRAFT: "Simpan Draf",
    SUBMIT: "Kirim",
    BACK: "Kembali",
    BACK_STEP: "Kembali ke Langkah Sebelumnya",
    REMOVE: "Hapus",
    DELETE: "Hapus",
    ADD_FILTER: "Tambah Filter",
    CREATE_ANOTHER_CHALLENGE: "BUAT TANTANGAN LAIN",
    UPLOAD_CSV: "Unggah CSV",
    UPLOAD_CSV_FILE: "Unggah File CSV",
    YES_SIGN_OUT: "Ya, keluar",
    DOWNLOAD_CSV: "Unduh CSV",
    CREATE_NEW: "Buat baru",
  },
  ERROR_MESSAGE: {
    DUPLICATED_SKU: "Beberapa SKU dan tag Kampanye duplikat.",
    EMPTY_FIELD: "Harap masukkan nilai.",
    INVALID_DATE: "Tanggal tidak valid.",
    INVALID_START_DATE: "Anda tidak bisa membuat tantangan di masa lalu.",
    INVALID_END_DATE: "Tanggal Akhir harus lebih lama dari Tanggal Mulai.",
    INVALID_URL: "Harap masukkan URL yang valid.",
    INVALID_NUMBER_VALUE: "Nilai angka tidak valid. Angka minimum adalah 0.",
    MAX_GREATER_THAN_0:
      "Kuantitas Maksimum harus berupa angka bulat lebih besar dari 0.",
    MAX_SIZE_GROUP_FILE_UPLOAD: "Ukuran file maksimum adalah 1MB.",
    MAX_SIZE_5MB: "Ukuran file maksimum adalah 5MB.",
    UNSUPPORTED_FILE:
      "Ini adalah file yang tidak valid, harap coba lagi mengikuti petunjuk di atas.",
    UNSUPPORTED_IMAGE: "Ekstensi file yang didukung: .jpg; .png",
    UNSUPPORTED_CSV: "Ekstensi file yang didukung: .csv",
    UNSUPPORTED_CSV_REWARDS_OFFERS_MULTIPLE:
      "Unggah file .csv dengan maksimal 5.000 baris dan kolom poc_id, campaign_id, points. Kolom dipisahkan dengan titik koma, campaign_id bersifat opsional.",
    EMPTY_FILE_FIELD: "Harap pilih file untuk diunggah.",
    UPSERT_ACCOUNT_GROUP:
      "Terjadi kesalahan saat membuat grup, harap coba lagi.",
    UPLOAD_FILE_ACCOUNT_GROUP:
      'Ada masalah dengan file Anda. Harap unggah file .csv dengan daftar ID POC di bawah kolom header "POC_ID"',
    NO_CHALLENGE_FOUND_BY_ID: "ID tantangan tidak valid.",
    CHALLENGE_FOUND_BY_ID:
      "ID Tantangan yang diberikan sudah ada, harap masukkan ID yang berbeda",
    NO_SKU_FOUND_BY_ID: "Harap masukkan SKU yang valid.",
    NO_RULE_FOUND_BY_ID: "ID aturan tidak valid.",
    SKU_QUANTITY_INVALID:
      "Kuantitas harus berupa angka bulat lebih besar dari 0.",
    NO_DUPLICATED_SKU: "SKU ini sudah terdaftar.",
    GENERIC_ERROR: "Ada yang salah. Harap coba lagi nanti.",
    PAGE_LEVEL_ERROR_DRAFT_MESSAGE:
      "Untuk menyimpan draf ini, harap isi kolom yang diperlukan di ",
    PAGE_LEVEL_ERROR_CHALLENGE_MESSAGE:
      "Untuk mengirimkan tantangan ini, harap isi semua kolom di ",
    PAGE_LEVEL_ERROR_CHALLENGE_MESSAGE_CONTINUE:
      "Untuk melanjutkan, harap isi semua kolom di ",
    INVALID_ORDER_ID: "ID Pesanan tidak valid",
    NO_REFUNDABLE_FOUND: "Tidak ditemukan combo yang dapat dikembalikan",
    CHALLENGE_SUBMIT_FAIL:
      "Tantangan tidak dapat dipublikasikan. Harap coba lagi.",
    INVALID_POC_ID: "POC ini tidak terdaftar dalam Program Rewards",
    INVALID_POINTS_VALUE:
      "Nilai Poin harus berupa angka bulat lebih besar dari 0.",
    GENERIC_LOAD_ERROR: "Ooops! Ada yang salah.",
    GENERIC_TRY_AGAIN: "Coba lagi",
  },
  ERROR_MESSAGE_USECASE: {
    UNABLE_UPSERT_ACCOUNT_GROUP: "Tidak dapat membuat grup akun",
    UNABLE_UPSERT_CHALLENGE: "Tidak dapat membuat tantangan",
    UNABLE_LOAD_GROUPS: "Tidak dapat memuat grup",
    UNABLE_LOAD_HISTORY_CHALLENGE: "Tidak dapat memuat riwayat tantangan",
  },
  CHALLENGE_FLOW: {
    NAME: "Tantangan",
    CHALLENGE_ID: "ID Tantangan",
    CHALLENGE_NAME: "Nama Tantangan",
    FLOW_NAME: "Buat Tantangan",
    FLOW_NAME_DUPLICATE: "Duplikasi Tantangan",
    STEP_NAME_1: "Pengaturan Tantangan",
    TAB_TITLE_1: "Pengaturan Tantangan",
    STEP_NAME_2: "Terapkan Filter",
    TAB_TITLE_2: "Terapkan Filter (opsional)",
    STEP_NAME_3: "Detail Tantangan",
    TAB_TITLE_3: "Detail Tantangan",
    CHALLENGE_TYPE: "Pilih Tipe",
    DIGITAL_POC_ID: "ID POC Digital",
    START_DATE: "Tanggal Mulai",
    END_DATE: "Tanggal Akhir",
    FILTER: "Filter",
    FILTERS: "Filter",
    FILTER_VALUES: "Nilai Filter",
    FILTER_INSTRUCTION: "Mulai ketik dan pilih beberapa nilai.",
    IMAGE_URL: "Gambar",
    CHALLENGE_TITLE: "Nama Tantangan",
    POINTS: "Poin",
    POINTS_VALUE: "Nilai Poin",
    STATUS: "Status",
    CHALLENGE_DESCRIPTION: "Deskripsi",
    GOOD_PHOTO_URL: "Contoh Foto yang Baik",
    BAD_PHOTO_URL: "Contoh Foto yang Buruk",
    CHALLENGE_INSTRUCTION: "Deskripsi Halaman Detail",
    CHALLENGE_TYPE_1: "Foto",
    CHALLENGE_TYPE_2: "Tandai sebagai Selesai",
    CHALLENGE_TYPE_PURCHASE_SINGLE: "Pembelian - Satu Pesanan",
    CHALLENGE_TYPE_PURCHASE_MULTIPLE: "Pembelian - Beberapa Pesanan",
    FILTER_LIST_SEGMENT: "Segmen",
    FILTER_LIST_POTENTIAL: "Potensial",
    FILTER_LIST_DDC: "DDC",
    FILTER_LIST_GROUP_IDS: "Grup",
    DIALOG_CONFIRMATION_HEADER: "Harap konfirmasi",
    DIALOG_CONFIRMATION_MESSAGE:
      "Harap konfirmasi bahwa Anda ingin mengirimkan tantangan ini.",
    DIALOG_CONFIRMATION_MESSAGE_DELETE_DRAFT:
      "Apakah Anda yakin ingin menghapus draf ini?",
    DIALOG_CONFIRMATION_DRAFT_MESSAGE:
      "Harap konfirmasi bahwa Anda ingin menyimpan draf.",
    CHALLENGE_SUBMITTED_HEADER: "Tantangan Dikirim",
    CHALLENGE_SUBMITTED_DESCRIPTION:
      "Lorem ipsum dolor sit amet lorem ipsum Lorem ipsum dolor sit amet lorem ipsum dolor sit amet",
    ERROR_UPSERT_CHALLENGE: "Kesalahan mencoba upsert tantangan ini.",
    CHALLENGE_CREATED_MESSAGE: "Tantangan Anda telah dibuat!",
    CHALLENGE_CREATED_DRAFT_MESSAGE: "Draf Anda telah disimpan",
    HISTORY_TITLE: "Riwayat Tantangan",
    HISTORY_DETAILS_TITLE: "Detail Tantangan",
    HISTORY_NEW_CHALLENGE_BTN: "+ BUAT TANTANGAN BARU",
    HISTORY_EMPTY_DATA_TABLE:
      "Saat ini Anda tidak memiliki riwayat tantangan. Harap buat tantangan baru.",
    HISTORY_DATE_FORMAT: "DD MMMM, YYYY",
    HISTORY_CHALLENGE_TYPE: "Tipe Tantangan",
    HISTORY_POC_FILTER: "Filter POC",
    HISTORY_DETAIL_PAGE_IMAGE: "Gambar",
    HISTORY_GOOD_PHOTO_BAD_PHOTO: "Foto Baik / Foto Buruk",
    HISTORY_EMPTY_FILTER_TABLE: "Tidak ada filter yang dipilih.",
    HISTORY_FILTERS_NOT_EDITABLE: "Filter tidak dapat diedit",
    HISTORY_STATUS_ACTIVE: "Aktif",
    HISTORY_STATUS_CREATED: "Dibuat",
    HISTORY_STATUS_EXPIRED: "Kedaluwarsa",
    HISTORY_STATUS_DRAFT: "Draf",
    HISTORY_STATUS_CANCELLED: "Dibatalkan",
    CANCEL_DIALOG_TITLE: "Batalkan Tantangan",
    CANCEL_DIALOG_INSTRUCTION:
      "Harap konfirmasi bahwa Anda ingin membatalkan tantangan ini dan pilih alasan di bawah untuk pembatalan.",
    CANCEL_DIALOG_REASON_TYPO_MISTAKE: "Typo / Kesalahan",
    CANCEL_DIALOG_REASON_STRATEGY_CHANGE: "Perubahan strategi",
    CANCEL_DIALOG_REASON_INCORRECT_TARGETING: "Penargetan POC yang tidak benar",
    CANCEL_DIALOG_REASON_OTHER: "Lainnya",
    SUBMIT: "Batalkan Tantangan",
    CANCEL_CHALLENGE: "Batalkan Tantangan",
    CANNOT_CANCEL_CHALLENGE:
      "Tantangan ini tidak bisa dibatalkan karena POC sudah mulai menyelesaikannya",
    CANCEL_CHALLENGE_SUCCESS_MESSAGE: "Tantangan ini telah dibatalkan",
    CANCEL_CHALLENGE_ERROR_MESSAGE: "Terjadi kesalahan. Harap coba lagi nanti.",
    EXECUTION_METHOD_MARK_AS_COMPLETE: "Tandai sebagai Selesai",
    EXECUTION_METHOD_TAKE_A_PHOTO: "Foto",
    EXECUTION_METHOD_PURCHASE_SINGLE_ORDER: "Pembelian - Satu Pesanan",
    EXECUTION_METHOD_PURCHASE_MULTIPLE_ORDER: "Pembelian - Beberapa Pesanan",
    SKU_ID_FIELD_LABEL: "Sku",
    SKU_QUANTITY_FIELD_LABEL: "Kuantitas",
    SKU_ADD_BUTTON: "TAMBAHKAN SKU",
    SUCCESSFUL_EDITION: "Perubahan Anda telah disimpan!",
    MENU: "Tantangan",
  },
  GROUP: {
    ADD_POC_GROUP: "Tambah Grup",
    ADD_PLUS_NEW_GROUP: "+ Tambah Grup Baru",
    GROUP_NAME: "Nama Grup",
  },
  DIALOG: {
    ROUTE_LEAVING_HEADER: "Harap konfirmasi",
    ROUTE_LEAVING_MESSAGE_2:
      "Jika Anda meninggalkan, {{name}} ini tidak akan disimpan.",
  },
  TRANSACTION_FLOW: {
    NAME: "Transaksi",
    TITLE: "Buat Transaksi Poin",
    TRANSACTION_TYPE: "Tipe Transaksi",
    TRANSACTION_TYPE_REFUND: "Pengembalian",
    TRANSACTION_TYPE_REWARDS_OFFER: "Penawaran Hadiah",
    TRANSACTION_SINGLE_OR_MULTIPLE_POC:
      "Apakah transaksi ini untuk satu POC atau beberapa POC?",
    SINGLE_POC: "Satu POC",
    MULTIPLE_POC: "Beberapa POC",
    ORDER_ID: "ID Pesanan",
    CONFIRMATION_DIALOG_TITLE: "Apakah Anda yakin ingin mengirimkan?",
    CONFIRMATION_DIALOG_CONFIRMATION: "Ya, kirimkan",
    CONFIRMATION_DIALOG_MESSAGE:
      "Transaksi ini tidak dapat dibatalkan setelah Anda kirim.",
    REFUND_COMBO_ID: "ID Combo",
    REFUND_QUANTITY: "Kuantitas",
    REFUND_POINTS_VALUE: "Nilai Poin",
    REFUND_DT_COMBOS: "DT Combos",
    REFUND_POINTS: "Poin",
    REFUND_POINTS_ABBREV: "pts",
    REFUND_SELECT_COMBOS_REFUND: "Pilih DT Combos untuk Pengembalian",
    REFUND_FULLY_REFUNDED: "Pengembalian Penuh",
    REFUND_ADD_COMBO_BUTTON: "TAMBAH ID COMBO",
    REFUND_POC_ID: "ID POC",
    REFUND_ORDER_DATE: "Tanggal Pesanan",
    REFUND_STATUS: "Status",
    REFUND_DATE_FORMAT: "DD MMMM, YYYY",
    ORDER_STATUS_PENDING: "Menunggu",
    ORDER_STATUS_PLACED: "Ditempatkan",
    ORDER_STATUS_CONFIRMED: "Dikonfirmasi",
    ORDER_STATUS_DENIED: "Ditolak",
    ORDER_STATUS_MODIFIED: "Dimodifikasi",
    ORDER_STATUS_IN_TRANSIT: "Dalam Pengiriman",
    ORDER_STATUS_CANCELLED: "Dibatalkan",
    ORDER_STATUS_DELIVERED: "Dikirim",
    ORDER_STATUS_PARTIAL_DELIVERY: "Pengiriman Parsial",
    ORDER_STATUS_INVOICED: "Tertagih",
    NOTIFICATION_SUCCESS: "Transaksi telah dibuat!",
    NOTIFICATION_REFUND_SUCCESS: "Pengembalian telah diproses dengan sukses!",
    NOTIFICATION_ERROR:
      "Terjadi kesalahan: Transaksi Anda tidak dibuat. Harap coba lagi.",
    REWARDS_OFFER_POC_ID: "ID POC",
    REWARDS_OFFER_POINTS: "Poin",
    REWARDS_OFFER_POINTS_VALUE: "Nilai Poin",
    REWARDS_OFFER_CAMPAIGN_ID: "ID Kampanye",
    REWARDS_OFFER_DESCRIPTION: "Deskripsi Penawaran",
    REWARDS_OFFER_ERROR: "Kesalahan",
    ACTIVITY_LOG_TITLE: "Penawaran Hadiah - Beberapa",
    ACTIVITY_LOG_INFO:
      "Berikut adalah daftar semua ID POC yang perlu ditinjau. Silakan unduh file berikut, perbaiki kesalahan, dan buat transaksi baru dengan file CSV yang diperbarui.",
    ERROR_ZERO_POINTS: "Poin tidak boleh nol",
    ERROR_NEGATIVE_POINTS: "Poin tidak boleh negatif",
    ERROR_COMMAS_OR_DOTS_POINTS: "Poin tidak boleh mengandung koma atau titik",
    ERROR_DEFAULT: "Kolom poin tidak valid",
    ERROR_INVALID_POC: "POC tidak terdaftar",
    ERROR_UNDEFINED: "Kesalahan yang tidak terdefinisi",
  },
  COMBO_FLOW: {
    NAME: "DT Combo",
    MENU: "Manajemen DT Combo",
    TITLE: "Tambah DT Combo",
    SUBMIT_TEXT: "Terbitkan",
    CONFIRMATION_DIALOG_TITLE:
      "Perubahan yang diterbitkan akan langsung muncul di aplikasi",
    CONFIRMATION_DIALOG_MESSAGE:
      "Harap konfirmasi jika Anda ingin menyimpan perubahan ini. Perubahan akan muncul di aplikasi segera setelah diterbitkan.",
    EDIT_DIALOG_TITLE: "Edit DT Combo",
    EDIT_DIALOG_NAME_LABEL: "Nama",
    EDIT_DIALOG_ID_LABEL: "ID DT Combo",
    POINTS_VALUE: "Poin",
    REMOVE_DIALOG_TITLE: "Hapus DT Combo",
    REMOVE_DIALOG_MESSAGE:
      "Apakah Anda yakin ingin menghapus DT Combo ini? Ini akan langsung dihapus dari aplikasi.",
    EDIT_QUANTITY_DIALOG_TITLE: "Jumlah Maksimum DT Combo",
    EDIT_QUANTITY_DIALOG_MESSAGE:
      "Jumlah ini akan menjadi jumlah maksimum yang dapat ditebus untuk setiap DT Combo.",
    MAX_QUANTITY: "Jumlah Maksimum",
    DT_COMBOS_ID: "ID DT Combo",
    DT_COMBOS_POINTS: "Poin",
    DT_COMBOS_REMOVE_BUTTON: "Hapus",
    DT_COMBOS_ADD_COMBO_BUTTON: "Tambah DT Combo",
    ERROR_MESSAGE_INVALID_COMBO_ID: "Harap masukkan ID DT Combo yang valid",
    ERROR_MESSAGE_DT_COMBO_ALREADY_INCLUDED: "ID DT Combo ini sudah termasuk",
    ERROR_MESSAGE_DT_COMBO_ALREADY_INCLUDED_PROGRAM:
      "ID DT Combo ini sudah termasuk dalam program",
    ERROR_MESSAGE_INVALID_POINTS_VALUE:
      "Poin harus berupa angka bulat lebih besar dari 0",
    NOTIFICATION_SUCCESS: "DT Combo baru telah diterbitkan!",
    NOTIFICATION_ERROR: "Combo tidak ditambahkan ke program. Harap coba lagi.",
    EDIT_SUCCESS: "DT Combo telah diperbarui!",
    EDIT_ERROR: "Ada kesalahan. Harap coba lagi nanti.",
    EDIT_MAX_QUANTITY_SUCCESS: "Jumlah Maksimum Redeem telah diperbarui!",
    EDIT_MAX_QUANTITY_ERROR: "Ada kesalahan. Harap coba lagi nanti.",
    REMOVE_SUCCESS: "DT Combo telah dihapus!",
    REMOVE_ERROR: "Ada kesalahan. Harap coba lagi nanti.",
    EMPTY_MESSAGE: "Tidak ada item yang ditambahkan",
    LIST_TITLE: "DT Combo Aktif",
    LIST_ADD_DT_COMBOS: "+ Tambah DT Combos",
    LIST_COLUMN_NAME: "Nama",
    LIST_COLUMN_COMBO_ID: "ID DT Combo",
    LIST_COLUMN_POINTS: "Poin",
    LIST_ACTION_EDIT: "Edit",
    LIST_ACTION_EDIT_REDEEM_LIMIT: "Edit",
    LIST_ACTION_REMOVE: "Hapus",
    LIST_SEARCH_COMBOS: "Cari ID DT Combo",
    LIST_MAX_QUANTITY: "Jumlah Maksimum DT Combo:",
  },
  RULES_FLOW: {
    MENU: "Aturan Earning",
    TITLE: "Aturan Earning",
    EARNING_NAME:
      "{{point}} poin untuk setiap {{amountSpent}} yang dibelanjakan",
    EARNING_NAME_plural:
      "{{point}} poin untuk setiap {{amountSpent}} yang dibelanjakan",
    EDIT_SKU: "Edit SKU",
    ADD_SKU: "Tambah SKU",
    ADD_SKU_MESSAGE:
      "Tambah SKU ke aturan earning ini agar pengguna dapat memperoleh poin. SKU hanya dapat ditambahkan ke satu aturan earning.",
    SKU_ID_FIELD: "SKU",
    EARNING_RULE_TABLE: "Aturan Earning",
    BUTTON_ADD: "TAMBAH",
    START: "Mulai",
    END: "Akhir",

    SKUS_LIST_ADD_SKU_BUTTON: "+ TAMBAH SKU",
    SKUS_LIST_SEARCH_SKUS: "Cari SKU",
    SKUS_LIST_COLUMN_SKU: "SKU",
    SKUS_LIST_COLUMN_SKU_NAME: "Nama SKU",
    SKUS_LIST_ACTION_CHANGE: "Ubah Aturan",
    SKUS_LIST_ACTION_REMOVE: "Hapus",
    SKUS_LIST_EMPTY_MESSAGE: "Tidak ada item yang ditambahkan",
    REMOVE_DIALOG_TITLE: "Hapus SKU",
    REMOVE_DIALOG_MESSAGE:
      "Apakah Anda yakin ingin menghapus SKU ini? Ini akan langsung dihapus dari aplikasi.",
    NOTIFICATION_SUCCESS_CHANGE_RULE: "Aturan earning SKU telah diubah",
    NOTIFICATION_ERROR_CHANGE_RULE: "Ada kesalahan. Harap coba lagi nanti.",
    NOTIFICATION_REMOVE_SUCCESS: "SKU telah dihapus",
    NOTIFICATION_REMOVE_ERROR: "Ada kesalahan. Harap coba lagi nanti.",
    ADD_SKU_SUCCESS: "SKU telah ditambahkan",
    ADD_SKU_ERROR: "Ada kesalahan. Harap coba lagi nanti.",

    VALIDATION_SKU_INVALID: "Harap masukkan SKU yang valid",
    VALIDATION_SKU_ALREADY_INCLUDED:
      "SKU ini sudah termasuk dalam Aturan Earning {{ruleName}}",
    OPTIONAL_FIELD: "(Opsional)",
    OPTIONAL_FIELD_HELPER_TEXT: "Isi hanya jika tersedia",
    EMPTY_DATA_TABLE: "Tidak ada item yang tersedia untuk ditampilkan",
  },
  CAMPAIGNS: {
    CAMPAIGNS_TITLE: "Kampanye",
    DDC: "DDC",
    SEGMENT: "Segmentasi",
    NOT_FOUND: "Tidak ada kampanye yang dapat ditampilkan.",
  },
  PRODUCTS: {
    NO_DATA_AVAILABLE: "Tidak ada data tersedia",
    UNIT_PRICE: "Harga (unit)",
    NOT_FOUND: "Tidak ada item yang dapat ditampilkan.",
  },
  ERRORHANDLERRETRY: {
    INFORMATION_NOT_FOUND: "Informasi tidak ditemukan",
    RELOAD_BUTTON_LABEL: "Muat ulang daftar",
  },
  MODAL_CAMPAIGNS: {
    CREATE_BUTTON: "Buat",
    EDIT_BUTTON: "Konfirmasi",
    CANCEL_BUTTON: "Batal",
    TITLE_MODAL_NEW_CAMPAIGN: "Kampanye Baru",
    TITLE_MODAL_EDIT_CAMPAIGN: "Edit Kampanye",
    CAMPAIGN_NAME: "Nama Kampanye",
    CAMPAIGN_TAG: "Nama Tag",
    SKU_ID: "SKU ID",
    DDC_OPTIONAL: "DDC (Opsional)",
    SEGMENT_OPTIONAL: "Segmentasi (Opsional)",
    CAMPAIGN_NAME_PLACEHOLDER: "Ketik nama kampanye di sini...",
    CAMPAIGN_DESCRIPTION: "Deskripsi Kampanye",
    DESCRIPTION: "Deskripsi",
    CAMPAIGN_SCRIPT: "Skrip Kampanye",
    CAMPAIGN_DESCRIPTION_PLACEHOLDER: "Ketik deskripsi di sini...",
    CAMPAIGN_SCRIPT_PLACEHOLDER: "Ketik skrip di sini...",
    TAG_PLACEHOLDER: "Ketik nama tag di sini...",
    DDC_PLACEHOLDER: "Ketik kode DDC di sini...",
    SEGMENT_PLACEHOLDER: "Ketik kode segmentasi di sini...",
    ERROR_HANDLER: "Ada yang salah. Harap coba lagi nanti.",
    ERROR_LIMIT_CSV:
      "Field CSV melebihi ukuran maksimum yang diizinkan yaitu 1MB.",
    MESSAGE_SUCCESS: "Kampanye berhasil dibuat.",
    MESSAGE_SUCCESS_UPDATE: "Kampanye berhasil diedit.",
    MESSAGE_SUCCESS_DELETE: "Kampanye berhasil dihapus.",
    MSG_MANDATORY_CAMPAIGN_NAME: "Kolom ini diperlukan.",
    MSG_MANDATORY_CAMPAIGN_TAG: "Kolom ini diperlukan.",
    MSG_MANDATORY_CAMPAIGN_DESCRIPTION: "Kolom ini diperlukan.",
    MSG_MANDATORY_CAMPAIGN_SCRIPT: "Kolom ini diperlukan.",
    MSG_MAX_SIZE_CAMPAIGN_TAG:
      "Kalimat terlalu panjang. Harap tidak melebihi 30 karakter.",
    MSG_NO_ACCEPT_SPACES_CAMPAIGN_TAG:
      "Kolom ini tidak menerima spasi. Harap tulis tanpa spasi.",
    MSG_START_WITH_PLACEHOLDER_CAMPAIGN_TAG:
      'Harap masukkan "#" sebelum nama tag.',
    MSG_AT_LEAST_ONE_CAMPAIGN_SKU: "Kolom ini diperlukan.",
    START_DATE: "Tanggal mulai",
    END_DATE: "Tanggal akhir",
    CSV_LIST: "Unggah ID POC",
    COVER_IMAGE: "Gambar Sampul (670x90)",
    DRAG_DROP_CSV: "Seret dan lepas file daftar CSV",
    DRAG_DROP_IMAGE: "Seret dan lepas gambar .png atau .jpg",
    CSV_FILE_REQUIRED: "Kolom ini diperlukan.",
    MSG_END_DATE_BEFORE_START_DATE:
      "Tanggal akhir tidak boleh lebih awal atau sama dengan tanggal mulai.",
    SEGMENTATION: "Spesialisasi Agen",
    SEGMENTATION_PLACEHOLDER: "contoh: 387",
    PRIMARY: "Misi Utama",
    SECONDARY: "Misi Sekunder",
    PRIMARY_TOOLTIP:
      "POC kampanye akan dipilih untuk dihubungi dengan Kampanye B2O sebagai misi utama.",
    SECONDARY_TOOLTIP:
      "POC kampanye akan dimasukkan sebagai misi tambahan untuk POC yang sudah direncanakan untuk dihubungi pada hari tersebut.",
    AGENT_SPECIALIZATION_TOOLTIP:
      "Tentukan segmentasi untuk mengidentifikasi agen spesifik yang akan bekerja pada kampanye ini.",
    DOWNLOAD_FILE: "Unduh contoh file di sini",
  },
  DELETE_CAMPAIGNS: {
    DELETE_CAMPAIGN: "Hapus kampanye",
    DELETE_CAMPAIGN_DESCRIPTION:
      "Apakah Anda yakin ingin menghapus kampanye ini?",
    DELETE: "HAPUS",
  },
  REQUIRED: {
    FIELD_REQUIRED: "Kolom ini diperlukan",
  },
  OPTIONS: {
    EDIT: "edit",
    DELETE: "hapus",
  },
  SEARCH_OPTIONS: {
    TOTAL_CAMPAIGN_NAMES: "hasil di Nama Kampanye",
    TOTAL_CAMPAIGN_NAME: "hasil di Nama Kampanye",
    TOTAL_DELIVERY_CENTER_IDS: "hasil di DDC",
    TOTAL_DELIVERY_CENTER_ID: "hasil di DDC",
    TOTAL_SEGMENTS: "hasil di Segmentasi",
    TOTAL_SEGMENT: "hasil di Segmentasi",
    TOTAL_SKUS: "hasil di SKU",
    TOTAL_SKU: "hasil di SKU",
    NO_CAMPAIGN: "Tidak ada kampanye yang dapat ditampilkan.",
  },
  AGENT_SEGMENTATION: {
    CONFIRM_SEGMENTATION: "Konfirmasi",
    DESCRIPTION_CONFIRM_SEGMENTATION:
      "Apakah Anda yakin ingin memperbarui segmentasi agen?",
    MODAL_TITLE: "Pengaturan agen",
    SEGMENTATION: "Segmentasi",
    AGENT: "Agen",
    CONFIRM_BUTTON: "KONFIRMASI",
    PLACEHOLDER_SEGMENTATION: "Pilih atau ketik nama agen",
    UPDATE_MESSAGE: "Segmentasi agen berhasil diperbarui.",
    ERROR_HANDLER: "Ada yang salah. Harap coba lagi nanti.",

    AGENT_SETUP: "Pengaturan agen",
    AGENT_SETUP_SUBTITLE:
      "Anda dapat mendefinisikan spesialisasi untuk seorang agen.",
    AGENT_SETUP_PLACEHOLDER: "Pilih agen",
    MISSIONS_MANAGEMENT_TITLE: "Manajemen misi",
    SPECIALIZATION_TITLE: "Spesialisasi",
    INSERT_SPECIALIZATION_PLACEHOLDER: "Masukkan spesialisasi",
    INSERT_SPECIALIZATION_ADD_BUTTON: "Tambah",
    SAVE_CHANGES: "Perubahan telah berhasil diterapkan.",
    SAVE_ERROR: "Ada yang salah! Perubahan tidak disimpan, coba terapkan lagi.",
    MISSIONS_AND_SPECIALIZATION_APPLY_CHANGES: "Terapkan perubahan",
    NO_AGENT_FOUND: "Agen tidak ditemukan",
    ERROR_TO_LOAD: "Tidak dapat memuat pengaturan agen",

    APPLY_CHANGES_MODAL_TITLE: "Apakah Anda yakin ingin menerapkan perubahan?",
    APPLY_CHANGES_MODAL_DESCRIPTION:
      "Hanya membutuhkan beberapa menit untuk mengaktifkan perubahan Anda.",
    APPLY_CHANGES_CONFIRM: "Ya, terapkan",
    APPLY_CHANGES_CANCEL: "Tidak, batalkan",
  },
  GLOBAL_MESSAGE: {
    NO_DATA_AVAILABLE: "Tidak ada data yang tersedia",
  },
  TYPE: {
    B2O: "B2O",
    FOCUS_SKU: "SKU Fokus",
  },
  POC: {
    POC_WITH_ERROR: "POC dengan kesalahan ",
    CONTACTED: "Dihubungi ",
    TO_BE_CONTACTED: "Akan dihubungi ",
  },
  BLOCK_LIST: {
    MODAL_TITLE: "Daftar blokir",
    CSV_TITLE: "Daftar CSV",
    CONFIRM_BUTTON: "Konfirmasi",
    DESCRIPTION: "Apakah Anda yakin ingin memperbarui daftar blokir?",
    UPDATE_MESSAGE: "Daftar blokir berhasil diperbarui dan akan berlaku besok.",
    DELETE_MESSAGE:
      "Daftar blokir berhasil dihapus. Perubahan ini akan berlaku besok.",
    ERROR_HANDLER: "Ada yang salah. Daftar blokir tidak diperbarui.",
    NO_CSV: "Daftar blokir tidak dapat diperbarui. Harap coba lagi.",
    CURRENT_BLOCK_LIST: "Daftar blokir saat ini tidak dapat dipulihkan.",
    LOADING: "Memuat daftar blokir…",
    DRAG_DROP_CSV: "Seret dan lepas file CSV",
    CURRENT_CSV: "Unduh daftar blokir saat ini",
    DELETE_DESCRIPTION:
      "Apakah Anda yakin ingin menghapus daftar blokir saat ini?",
    REFRESH_PAGE: "Segarkan halaman",
  },
  MANAGEMENT: {
    TITLE: "Manajemen",
  },
  MISSIONS_MANAGEMENT: {
    TITLE: "Manajemen misi",
    PROMOTE_REWARDS_CHALLENGE_MISSION_TITLE: "Tantangan",
    SPONSORED_MISSION_TITLE: "Disponsori",
    SERVICE_MISSION_TITLE: "Layanan",
    SERVICE_MISSION_TOOLTIP: "Misi ini membutuhkan eksklusivitas agen",
    LOCAL_MISSIONS_TITLE: "Misi lokal",
  },
  LOCAL_MISSIONS: {
    TITLE: "Misi lokal",
  },
  TEAM_STRUCTURE: {
    TITLE: "Struktur tim",
  },
  GLOBAL_MISSIONS: {
    TITLE: "Misi global",
  },
  TEAM_MANAGEMENT: {
    TITLE: "Tim",
  },
};
