import { IRecommendation } from '../../../../hooks/useNotificationMassApproval';

export const adapterRecommendation = (recommendation): Omit<IRecommendation, 'previousStatus'> => {
  if (!recommendation) return null;

  return {
    id: recommendation?.id ?? '',
    status: recommendation?.status ?? '',
    referenceName: recommendation?.reference_name ?? '',
    description: recommendation?.description ?? '',
    total: recommendation?.total_count ?? 0,
    validated: recommendation?.processed_count ?? 0,
    failed: recommendation?.failed_count ?? 0,
    processed: recommendation?.processed_count ?? 0,
  };
};
