import { usePreferredLanguageV2 } from 'admin-portal-shared-services';

import { DateFormats } from '@/components/Overview/enums/visits';
import { Locales } from '@/enums/locales';
import { formatDate } from '@/utils/dateFormatter/dateFormatter';

import * as Styled from '../../components/TableVisits/TableVisits.styles';
import {
  ACCESS_MAX_TIME,
  ACCESS_MIN_TIME,
  ACCESSOR_BDR_ACCOUNT_ID,
  ACCESSOR_BDR_ID,
  ACCESSOR_STATUS,
  ACCESSOR_VISIT_DATE,
  STATUS,
} from '../../consts/TableVisits';
import { useTranslation } from '../useTranslation/useTranslation';

export const useTableVisits = () => {
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2() as {
    preferredLanguage: Locales;
    defaultLanguage: Locales;
  };
  const locale = preferredLanguage ?? defaultLanguage;
  const {
    bdrIdColumn,
    bdrAccountIdColumn,
    visitDateColumn,
    statusColumn,
    minTimeColumn,
    maxTimeColumn,
    scheduled,
    inProgress,
    canceled,
    justified,
    completed,
    minutes,
  } = useTranslation();

  const statusStyles = {
    [STATUS.CANCELED]: { $bgcolor: '#FFE6E6', $labelcolor: '#E82727', label: canceled },
    [STATUS.SCHEDULED]: { $bgcolor: '#E6F9E6', $labelcolor: '#009900', label: scheduled },
    [STATUS.COMPLETED]: { $bgcolor: '#F2F2F2', $labelcolor: '#585858', label: completed },
    [STATUS.IN_PROGRESS]: { $bgcolor: '#E6F2FF', $labelcolor: '#006EDB', label: inProgress },
    [STATUS.JUSTIFIED]: { $bgcolor: '#FFF4E6', $labelcolor: '#D47300', label: justified },
  };

  const getMaxAndMinVisitTimeLabel = (timeInMinutes: number) => {
    const formattedTimeInMinutes = timeInMinutes > 1 ? `${timeInMinutes} ${minutes}` : `${timeInMinutes} ${minutes.slice(0, -1)}`;
    return formattedTimeInMinutes.toLowerCase();
  }

  const columns = [
    {
      Header: bdrIdColumn,
      accessor: ACCESSOR_BDR_ID,
      disableSortBy: true,
    },
    {
      Header: bdrAccountIdColumn,
      accessor: ACCESSOR_BDR_ACCOUNT_ID,
      disableSortBy: true,
    },
    {
      Header: statusColumn,
      accessor: ACCESSOR_STATUS,
      disableSortBy: true,
      Cell: ({ value }) => {
        const style = statusStyles[value];
        return <Styled.BadgeStatus {...style} />;
      },
    },
    {
      Header: visitDateColumn,
      accessor: ACCESSOR_VISIT_DATE,
      defaultSortAsc: true,
      Cell: ({ value }) => {
        return formatDate(new Date(value), locale, DateFormats.MEDIUM);
      },
    },
    {
      Header: minTimeColumn,
      accessor: ACCESS_MIN_TIME,
      disableSortBy: true,
      Cell: ({ value }) => {
        return getMaxAndMinVisitTimeLabel(value);
      }
    },
    {
      Header: maxTimeColumn,
      accessor: ACCESS_MAX_TIME,
      disableSortBy: true,
      Cell: ({ value }) => {
        return getMaxAndMinVisitTimeLabel(value);
      }
    },
  ];

  return {
    columns,
  };
};
