import React, { useEffect } from 'react';

import { Driver, Location } from '@hexa-ui/icons';
import { SidebarItem, useSidebar } from 'admin-portal-shared-services';
import {
  useServicesContext,
  useUserActiveAudiencesStore,
} from 'deliver-portal-commons';

import { PageProps } from './routerViewModel.types';

export function DriverIcon() {
  return <Driver />;
}

export function LocationIcon() {
  return <Location />;
}

export function useRouterViewModel() {
  const { i18n, platformUser } = useServicesContext();

  const { setUserId } = useUserActiveAudiencesStore();
  const userId = platformUser.getStoreValue().id;

  useEffect(() => {
    setUserId(userId);
  }, [userId, setUserId, platformUser]);

  const baseRoute = '/route-planning';

  const DriverPortfolioPage: PageProps = {
    Element: React.lazy(
      () => import('../../../pages/driverPortfolio/DriverPortfolioPage'),
    ),
    path: `${baseRoute}/driver-portfolio`,
    sideBarItem: {
      title: i18n.formatMessage({
        id: 'Hive_DriverPortfolioPage.SIDEBAR_TITLE',
      }),
      icon: DriverIcon,
      path: `${baseRoute}/driver-portfolio`,
      id: 'drivers-portfolio',
    },
  };

  const DriverProfilePage: PageProps = {
    ...DriverPortfolioPage,
    Element: React.lazy(
      () => import('../../../pages/driverProfile/DriverProfilePage'),
    ),
    path: `${baseRoute}/driver-portfolio/:id`,
  };

  const RoutesPage: PageProps = {
    Element: React.lazy(() => import('../../../pages/routes/RoutesPage')),
    path: `${baseRoute}/routes`,
    sideBarItem: {
      title: i18n.formatMessage({
        id: 'Hive_RoutesPage.SIDEBAR_TITLE',
      }),
      icon: LocationIcon,
      path: `${baseRoute}/routes`,
      id: 'routes',
    },
  };

  const CreateRoutesPage: PageProps = {
    ...RoutesPage,
    Element: React.lazy(
      () => import('../../../pages/createRoutes/CreateRoutes'),
    ),
    path: `${baseRoute}/routes/create-routes`,
  };

  const sidebarItems = [
    DriverPortfolioPage.sideBarItem,
    RoutesPage.sideBarItem,
  ] as Array<SidebarItem>;
  useSidebar({ items: sidebarItems, utils: [] });

  return {
    baseRoute,
    DriverPortfolioPage,
    DriverProfilePage,
    RoutesPage,
    CreateRoutesPage,
  };
}
