import MessageMap from '../i18n.d';

const SKU_PRIOTIRY_FROM_TO = 'Priority {from} to {to}';

const ptBR: MessageMap = {
  test: {
    translate: 'Tradução',
    missing: 'Only English option available'
  },
  // NEED COPYDECK FOR DYNAMIC VALIDATIONERRORS
  DynamicComponents: {
    ValidationErrors: {
      Required: 'Campo obrigatório.',
      IsChecked: 'This field should be checked.',
      MinLength: 'Deve ter pelo menos {value} caracteres.',
      MinValue: 'Deve ser um valor maior que {value}.',
      MaxValue: 'Deve ser um valor menor que {value}.',
      MaxValueBasedOnField: 'Must be a value smaller than {value} ("{field}" field).',
      MinValueBasedOnField: '{primary_field} deve ser maior ou igual à {field}.',
      AlphaNumeric: 'The value must be alphanumeric. Only letters, numbers and dash are allowed.'
    },
    OptionalLabel: '(opcional)',
    Components: {
      budgetId: {
        Label: 'ID do orçamento',
        Placeholder: 'Insira o código de ID do orçamento'
      },
      title: {
        Label: 'Título',
        Subtitle: 'Este título será exibido aos clientes.',
        Placeholder: 'Inserir título'
      },
      titleInput: {
        Label: 'Title',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      startDateAndEndDate: {
        Label: 'Intervalo de datas do touchpoint',
        Placeholder: 'Inserir período'
      },
      description: {
        Label: 'Descrição',
        Subtitle: 'Esta descrição será exibida aos clientes.',
        Placeholder: 'Inserir descrição'
      },
      allocation: {
        Label: 'Alocação',
        Tooltip:
          'A porcentagem de PDVs recomendadas do público selecionado para receber o desconto.',
        Placeholder: 'Insira a porcentagem de alocação'
      },
      skuTable: {
        Label: 'SKU Table',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      pointsValue: {
        Label: 'Pontos',
        Placeholder: 'Inserir o número de pontos'
      },
      rewardsCampaignId: {
        Label: 'ID do grupo da campanha',
        Placeholder: 'Insira o ID do grupo de campanha',
        Tooltip:
          'Use este campo para associar este touchpoint a outras campanhas que você possa estar executando em outras plataformas.'
      },
      eligibleOrderMaximum: {
        Label: 'Máximo de pedidos por PDV',
        Tooltip: 'O número máximo de pedidos elegíveis que cada PDV pode fazer durante a promoção.',
        Placeholder: 'Insira um número'
      },
      skuLimit: {
        Label: 'Máximo de SKUs por PDV',
        Tooltip: 'A quantidade máxima de cada SKU que um PDV pode comprar.',
        Placeholder: 'Insira um número'
      },
      discountAndQuantities: {
        errors: {
          minQtyMessageError: 'A quantidade mínima deve ser maior ou igual a 1.',
          maxQtyMessageError: 'A quantidade máxima deve ser maior ou igual a quantidade mínima.',
          discountMessageError: 'O desconto deve ser maior ou igual a 1.',
          minDiscountMessageError: 'O desconto mínimo deve ser maior ou igual a 1.',
          maxDiscountMessageError: 'O desconto máximo deve ser maior ou igual ao desconto mínimo.',
          discountTieredMessageError: 'O desconto deve ser maior ou igual ao do nível anterior.'
        },
        type: {
          nonPersonalized: {
            Label: 'Descontos e quantidades',
            Subtitle: 'Os valores abaixo serão aplicados a todos os SKUs selecionados.'
          },
          personalized: {
            Label: 'Descontos e quantidades',
            Subtitle:
              'Preencha os campos abaixo para que nosso algoritmo possa recomendar os melhores descontos para cada PDV.'
          },
          tieredNonPersonalized: {
            Label: 'Descontos e quantidades',
            Subtitle: 'Os valores abaixo serão aplicados a todos os SKUs selecionados.'
          },
          tieredPersonalized: {
            Label: 'Descontos e quantidades',
            Subtitle:
              'Preencha pelo menos 2 níveis abaixo para que nosso algoritmo possa recomendar os melhores descontos para cada PDV.'
          }
        },
        minQuantity: {
          Label: 'Quantidade mínima por pedido',
          Placeholder: 'Insira um número'
        },
        maxQuantity: {
          Label: 'Quantidade máxima por pedido',
          Placeholder: 'Insira um número'
        },
        minDiscount: {
          Label: 'Desconto mínimo',
          Placeholder: 'Insira a porcentagem de desconto'
        },
        maxDiscount: {
          Label: 'Desconto máximo',
          Placeholder: 'Insira a porcentagem de desconto'
        },
        fixDiscount: {
          Label: 'Desconto',
          Placeholder: 'Insira a porcentagem de desconto'
        },
        // This is just a tierTable copydeck example, you can change the name of the fields to match your needs
        tierTable: {
          AddTierButton: 'Adicionar nível',
          Title: 'Nível',
          MinQuantity: 'Quantidade mínima',
          MaxQuantity: 'Quantidade máxima',
          Discount: 'Desconto %',
          // missing copydeck Actions
          Actions: 'Remover'
        }
      },
      Completo: {
        Label: 'Testing value input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      testingNumber: {
        Label: 'Testing value input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      limitMaxField: {
        Label: 'Max input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      limitMinField: {
        Label: 'Min input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      TEST: {
        Label: 'Test input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      bannerTitle: {
        Label: 'Banner title',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      numberOfUnits: {
        Label: 'Unidades',
        Subtitle: 'O número de unidades necessárias para concluir a oferta.',
        Placeholder: 'Inserir o número de unidades'
      },

      dateField: {
        Label: 'Date Field',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      checkboxField: {
        Label: 'Checkbox Field',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      }
    }
  },
  Components: {
    DatePicker: {
      Label: 'Intervalo de datas do touchpoint',
      Placeholder: 'Inserir período',
      Error: 'Inserir um novo período.'
    },
    SkuLimit: {
      Label: 'Máximo de SKUs por PDV',
      Tooltip: 'A quantidade máxima de cada SKU que um PDV pode comprar.',
      Placeholder: 'Insira um número'
    }
  },
  Breadcrumb: {
    CampaignList: 'Lista de ofertas',
    NewCampaign: 'Criar oferta',
    CampaignDetails: 'Detalhes da oferta'
  },
  NewCampaign: {
    Heading: 'Criar oferta',
    BreadcrumbLabel: 'Criar nova campanha',
    SubHeading: 'Estratégia',
    Info: 'Selecione uma estratégia principal e secundária. Essas informações potencializarão a eficiência da sua campanha.',
    Cancel: 'Cancelar',
    Create: 'Criar',
    CampaignsTitle: 'Campanha',
    CampaignPageTitle: 'Lista de ofertas'
  },
  countryChangeConfirmationModal: {
    Title: 'Alterar país?',
    Description: 'Se você alterar o país, as alterações não salvas serão perdidas.',
    Cancel: 'Não, voltar',
    Confirm: 'Sim, alterar'
  },
  vendorChangeConfirmationModal: {
    Title: 'Alterar fornecedor?',
    Description: 'Se você alterar o fornecedor, as alterações não salvas serão perdidas.',
    Cancel: 'Não, voltar',
    Confirm: 'Sim, alterar'
  },
  sellerChangeConfirmationModal: {
    Title: 'Alterar vendedor?',
    Description: 'Se você alterar o vendedor, as alterações não salvas serão perdidas.',
    Cancel: 'Não, voltar',
    Confirm: 'Sim, alterar'
  },
  HomePage: {
    Levers: {
      Title: 'Lista de ofertas',
      Table: {
        Name: 'Nome da oferta',
        Category: 'Categoria',
        Touchpoint: 'Touchpoint types',
        CampaignId: 'Código da oferta',
        Period: 'Período',
        Status: 'Status',
        Challenge: 'Desafio',
        Promotion: 'Promoção',
        DigitalCommunication: 'Comunicação digital',
        PageSizeOptions: 'Itens por página: {options}',
        QuantityIndicator: 'de'
      }
    }
  },
  StrategyCard: {
    Strategies: {
      INTRODUCE: 'Apresentar',
      SUSTAIN: 'Sustentar',
      GENERATE_DEMAND: 'Gerar demanda',
      OPERATIONS: 'Operações'
    },
    StrategyDescription: {
      INTRODUCE: 'Aumentar a cobertura de produtos novos ou existentes.',
      SUSTAIN: 'Incentivar compras repetidas e aumentar a frequência das compras.',
      GENERATE_DEMAND: 'Aumentar as compras estrategicamente.',
      OPERATIONS: 'Impulsionar compras para gerenciar operações diárias e bater metas de rotina.'
    },
    SelectLabel: 'Selecione a estratégia secundária',
    SubStrategies: {
      I1: 'I1: Atrair clientes para experimentar novos produtos',
      I2: 'I2: Melhorar a cobertura de produtos existentes',
      I3: 'I3: Oferta de boas-vindas para B2B',
      S1: 'S1: Incentivar clientes a repetir compras',
      G1: 'G1: Impulsionar o fluxo de consumidores com eventos = promoção',
      G2: 'G2: Incentivar PDVs a manterem a adesão diária aos preços',
      G3: 'G3: Aumentar o consumo repassando um preço mais baixo para consumidores',
      G4: 'G4: Otimizar a variedade em PDVs',
      G5: 'G5: Negociar posição em destaque, display, prateleiras ou planograma',
      G6: 'G6: Programa para trade - desconto por bater meta',
      G7: 'G7: Gerar demanda em SKUs com pouca venda ou próximos da validade para reduzir perdas na cadeia de suprimentos',
      O1: 'O1: Utilizar promoções para bater a meta mensal/trimestral',
      O2: 'O2: Responder à dinâmica do mercado',
      O3: 'O3: Desconto relacionado ao pagamento (como desconto em dinheiro)',
      O4: 'O4: Reduzir a carga de distribuição (como picos de demanda previstos com base na ocasião, distribuição para armazéns KA, etc.) com base na ocasião',
      O5: 'O5: Gerar demanda em poucas vendas ou reduzir perdas na cadeia de suprimentos',
      O6: 'O6: Outros (desconto para funcionários, recompensas, etc.)'
    }
  },

  chipCopywrite: {
    ddc: '{value}',
    'ddc-uc': '{value}',
    segment: '{value}',
    channel: '{value}',
    'sales-uen': '{value}',
    'sales-state': '{value}',
    'sales-region-drv': '{value}',
    'sales-region-geo': '{value}',
    'digital-order-since-comparison': '{operator} {value, plural, one {# dia} other {# dias}}',
    'digital-order-since': 'Last {value, plural, one {# dia} other {# dias}}',
    'engagement-score': '{value}',
    'orders-count': '{operator} {value}',
    'purchased-marketplace-count': '{operator} {value}',
    'purchased-sku-count': '{operator} {operatorValue}: {value}',
    'sku-priority-range-id': SKU_PRIOTIRY_FROM_TO,
    'sku-priority-introduce-range-id': SKU_PRIOTIRY_FROM_TO,
    'sku-priority-sustain-range-id': SKU_PRIOTIRY_FROM_TO,
    'sku-priority-generate-range-id': SKU_PRIOTIRY_FROM_TO,
    'service-model': '{model}: {vendor}',
    label: {
      ddc: 'Comprou de DDC',
      'ddc-uc': 'Comprou de DDC',
      segment: 'Segment',
      channel: 'Canal',
      'sales-uen': 'UEN',
      'sales-state': 'Estado',
      'sales-region-drv': 'Região de vendas',
      'sales-region-geo': 'Região de vendas',
      'digital-order-since-comparison': 'Último pedido em',
      'digital-order-since': 'Último pedido em',
      'engagement-score': 'Uma pontuação de engajamento',
      'orders-count': 'Pediu uma quantidade',
      'purchased-marketplace-count': 'Purchased marketplace',
      'purchased-sku-count': 'SKU comprado',
      'sku-priority-range-id': 'Priorização de SKU',
      'sku-priority-introduce-range-id': 'Introduce',
      'sku-priority-sustain-range-id': 'Sustain',
      'sku-priority-generate-range-id': 'Generate',
      'service-model': 'Service model'
    }
  },
  operators: {
    greaterThan: 'Mais de',
    lowerThan: 'Menos de',
    equals: 'Igual a',
    yes: 'Sim',
    no: 'Não'
  },
  Expressions: {
    High: 'Alto',
    Medium: 'Médio',
    Low: 'Baixo'
  },
  SettingsPage: {
    Title: 'Configurações',
    Description: 'Adicione nome, período e SKUs.',
    DetailsCard: {
      Title: 'Configurações',
      Name: 'Nome da oferta',
      Error: 'O nome deve ter pelo menos 3 caracteres.',
      ErrorNameUsed: 'O nome da campanha já está em uso. Tente outro.',
      Period: {
        Label: 'Período',
        Placeholder: 'Inserir período',
        Error: 'Inserir um novo período'
      }
    },
    StrategyComponent: {
      Strategy: 'Estratégia',
      Substrategy: 'Subestratégia'
    },
    SkuCard: {
      Title: 'SKUs',
      Subtitle: 'Adicionar os SKUs que estarão relacionados a esta oferta.',
      Product: 'Produto',
      Code: 'Código de SKU',
      Remove: 'Remover',
      AddSKU: 'Selecionar SKUs',
      AddBySearch: 'Adicionar por pesquisa',
      AddByCSV: 'Adicionar por arquivo CSV',
      CSVUploadModal: {
        Title: 'Adicionar SKUs por arquivo CSV',
        SubTitle: 'Baixe, preencha e carregue o modelo do arquivo.',
        DownloadButton: 'Baixar o modelo do arquivo',
        DragAndDropTitle: 'Arraste seu arquivo CSV aqui ou pesquise',
        Limit: 'Limite: 300 linhas/IDs.',
        DragAndDropSubtitle:
          'O nome do arquivo não deve conter espaços, sublinhados ou caracteres especiais, exceto “-”.',
        UploadButton: 'Carregar arquivo',
        Error: 'Seu arquivo excede 20 MB.Selecione um arquivo menor.',
        CancelButton: 'Cancelar'
      }
    },
    EmptySku: {
      Message:
        'Nenhum SKU selecionado ainda. Este tipo de oferta requer que você adicione pelo menos um SKU para continuar.',
      Action: 'Selecionar SKUs'
    },
    EmptyState: {
      Message: 'Nenhum SKU para exibir.'
    },
    ModalEditSku: {
      Title: 'Editar intervalo de datas da campanha?',
      Subtitle: 'Esta ação redefinirá todas as datas definidas nos touchpoints.',
      Action: 'Sim, editar',
      Cancel: 'Não, cancelar'
    },
    ModalAddSku: {
      Title: 'Adicionar SKUs?',
      Subtitle: 'Isso redefinirá os SKUs selecionados dentro dos touchpoints.',
      Action: 'Sim, adicionar',
      Cancel: 'Não, cancelar'
    },
    ModalRemoveSku: {
      Title: 'Remover SKUs?',
      Subtitle: 'Isso redefinirá os SKUs selecionados dentro dos touchpoints.',
      Action: 'Sim, remover',
      Cancel: 'Não, cancelar'
    },
    ModalAddSKUPopModel: {
      Title: 'Adicionar SKUs?',
      Subtitle:
        'Isso vai alterar a estimativa do Smart audience e redefinir quaisquer SKUs selecionadas dentro dos detalhes dos touchpoints.',
      Action: 'Sim, adicionar',
      Cancel: 'Não, cancelar'
    },
    ModalRemoveSKUPopModel: {
      Title: 'Remover SKUs?',
      Subtitle:
        'Isso vai alterar a estimativa do Smart audience e redefinir quaisquer SKUs selecionadas dentro dos detalhes dos touchpoints.',
      Action: 'Sim, remover',
      Cancel: 'Não, cancelar'
    },
    ReviewSKUsWithErrorsAlert: {
      Message: 'There are {value} SKUs with errors. Review them and upload a new file.',
      Action: 'Review SKUs'
    },
    ModalReviewSKUsWithErrors: {
      Title: 'Review SKUs with errors',
      Subtitle: `These SKUs could be inactive or invalid. To fix errors, review them and upload a new file. SKUs with errors won't be included in the offer.`,
      Action: 'Close'
    },
    Alert:
      'As datas de execução desta campanha já passaram. Selecione um novo período para publicar este rascunho.'
  },
  AudiencePage: {
    Title: 'Público',
    Description: 'Especifique quem será afetado.',
    warningText:
      'O Smart audience está ativado, portanto, o número estimado de PDVs alvo pode mudar. A nova estimativa com os PDVs sugeridos para esta oferta será exibida na página Resumo.'
  },
  TouchpointsPage: {
    Touchpoints: 'Touchpoints',
    Title: 'Touchpoints',
    Description: 'Escolha um tipo de interação.',
    Modal: {
      Title: 'Selecionar touchpoint',
      Search: 'Pesquisar por categoria ou nome do touchpoint',
      Action: 'Selecionar touchpoint',
      Cancel: 'Voltar',
      Results: 'results',
      NoResultsFound: 'No results found. Try changing your search',
      ClearSearch: 'Clear search'
    },
    EmptyStateCard: {
      Title: 'Nenhum touchpoint selecionado ainda',
      Subtitle: 'O touchpoint que você selecionar aparecerá aqui.',
      ButtonLabel: 'Selecionar touchpoint '
    },
    EditButton: 'Editar touchpoint',
    editDialog: {
      Title: 'Editar touchpoint?',
      Subtitle:
        'Isso redefinirá todos os detalhes que você possa ter adicionado a este touchpoint.',
      Action: 'Sim, editar',
      Cancel: 'Não, voltar'
    },
    RemoveModal: {
      Title: 'Remover touchpoint?',
      Subtitle: 'Isso excluirá o touchpoint e todos os seus detalhes.',
      Action: 'Sim, remover',
      Cancel: 'Não, voltar'
    }
  },
  TouchpointDetailsPage: {
    TouchpointType: 'Pedido múltiplo',
    Title: 'Detalhes do touchpoint',
    Card: {
      Title: 'Quantidade de SKU'
    },
    Description: 'Configure as informações.',
    SelectedAudienceCard: {
      Title: 'Público selecionado'
    },
    SkuCard: {
      AlertText: 'Selecionar SKU única criará vários desafios com o mesmo conteúdo e pontos.',
      SingleSkuTitle: 'SKU única',
      SingleSkuDescription: 'O PDV recebe vários desafios com 1 SKU cada.',
      MultipleSkuTitle: 'Diversas SKUs',
      MultipleSkuDescription: 'O PDV recebe 1 desafio com todas as SKUs selecionadas abaixo.',
      ChallengeskuLabel: 'Exibição do desafio'
    },
    AudienceSelectedItem: {
      Trait: 'atributo',
      Traits: 'atributos',
      CSVFile: 'CSV file',
      TextButton: 'Mostrar tudo',
      TraitBox: 'Atributos'
    },
    DetailsCard: {
      Title: 'Detalhes do touchpoint',
      EmptyQuantity: {
        Message:
          'Nenhuma quantidade foi definida.{br} Você deve adicionar uma quantidade para cada SKU no desafio.',
        Action: 'Definir quantidade'
      },
      CoverImage: {
        message1: 'O arquivo deve ter formato JPG ou PNG',
        message2: 'e até 1MB de tamanho.',
        error: 'O tamanho ou formato do arquivo não é válido. Tente carregar novamente.',
        button: 'Selecionar arquivo',
        label: 'Imagem da capa'
      },
      Badge: {
        completed: 'Concluída',
        pending: 'Pendente'
      },
      ItemCard: {
        Category: 'Desafio de recompensas',
        TouchpointType: 'Pedido múltiplo'
      },
      DatePicker: {
        Label: 'Intervalo de datas do desafio'
      },
      InputTitle: {
        Label: 'Título',
        Placeholder: ''
      },
      InputDescription: {
        Label: 'Descrição',
        Placeholder: 'Inserir uma descrição'
      },
      InputPoints: {
        Label: 'Pontos',
        Placeholder: 'Inserir o número de pontos'
      },
      InputCampaignGroupId: {
        Label: 'ID do grupo da campanha',
        Placeholder: ' ',
        Optional: '(opcional)',
        Tooltip:
          'Use este campo para associar este touchpoint a outras campanhas que você possa estar executando em outras plataformas.'
      },
      InputUnits: {
        Label: 'Unidades',
        Hint: 'O número de unidades necessárias para concluir a oferta.',
        Placeholder: 'Inserir o número de unidades'
      },
      SKUQuantityTable: {
        Title: 'Quantidade de SKU',
        EditQuantity: 'Editar quantidade',
        Product: 'Produto',
        SKUCode: 'Código de SKU',
        Quantity: 'Quantidade',
        RewardsChallengeId: 'ID do desafio de recompensas'
      },
      ValidationErrors: {
        minLength: 'Deve ter pelo menos {value} caracteres.',
        minValue: 'Deve ser um valor maior que {value}.'
      },
      SkuRemoveTable: {
        Title: 'SKUs',
        AddSKU: 'Adicionar SKUs',
        EditSKU: 'Editar SKUs',
        EmptyQuantity: {
          Message: 'Nenhum SKU adicionado. Você deve adicionar pelo menos um SKU para continuar.'
        }
      }
    },
    SKUQuantity: {
      Dialog: {
        Title: 'Definir quantidade',
        Cancel_Button: 'Cancelar',
        Apply_Button: 'Aplicar'
      }
    },
    MultipleOrder: {
      Title: 'Pedido múltiplo',
      Description:
        'Um desafio que permite que os PDVs ganhem pontos comprando os SKUs necessários dentro de um determinado prazo.'
    }
  },
  SummaryPage: {
    Title: 'Resumo',
    Description: 'Revise e envie.',
    SKUSettingsCardTable: {
      Tooltip: 'Nenhum touchpoint associado.'
    },
    Alert:
      'Alguns SKUs não serão incluídos nesta oferta porque não estão associados a touchpoints. Quando o Smart audience está ativado, isso pode mudar o número estimado de PDVs alvo.',
    AlertButton: 'Mostrar SKUs',
    SkusWithoutTouchpointsModal: {
      Title: 'SKUs sem touchpoints',
      Subtitle:
        'Você deve associar esses SKUs a touchpoints se quiser incluí-los nesta oferta. Caso contrário, eles não serão incluídos. Vá para a página de Detalhes dos touchpoints para associá-los a um touchpoint.',
      Paragraph: 'Para adicionar esses SKUs a um touchpoint, vá para Detalhes de touchpoints.',
      ConfirmMessage: 'Ir para Detalhes de touchpoint',
      CancelMessage: 'Fechar'
    },
    PopModelAlert: {
      Failed:
        'Nenhum PDV estimado foi direcionado após a ativação do Smart audience. Revise as SKUs ou escolha outro público.',
      Processed: 'O número de PDVs alvo mudou devido à ativação do Smart audience.',
      Processing:
        'O número estimado de PDVs alvo está em andamento devido à ativação do público inteligente. Ele será atualizado automaticamente quando concluído.'
    },
    SKUTable: {
      Title: 'SKUs'
    },
    DiscountItemsStepsTable: {
      Title: 'Descontos e quantidades',
      Tier: 'Nível',
      MinQuantity: 'Quantidade mínima por pedido',
      MaxQuantity: 'Quantidade máxima por pedido',
      FixDiscount: 'Desconto %'
    }
  },
  StepActions: {
    Next: 'Próximo',
    Back: 'Voltar',
    SaveAndExit: 'Salvar e sair',
    Publish: 'Publicar'
  },
  AudienceCard: {
    Title: 'Público',
    EditButton: 'Editar',
    AudienceName: 'Nome do público',
    EstimatedPOCsTargeted: 'POCs alvo estimados',
    Traits: 'Atributos',
    ShowAll: 'Mostrar tudo',
    MoreTraits: '{value} atributos',
    OneTrait: '{value} atributo',
    CSVUploaded: 'CSV uploaded'
  },
  DetailsPage: {
    Title: 'Detalhes da oferta',
    PublishedBy: 'Publicado por',
    PublishersEmail: 'E-mail do editor',
    PublishedAt: 'Publicado em',
    publishedTime: 'em'
  },
  LeaveDialog: {
    Title: 'Sair da criação da oferta?',
    Description: 'Todas as alterações não salvas serão perdidas.',
    Cancel: 'Não, voltar',
    CancelOnSideBar: 'Não, continuar a criação',
    Leave: 'Sim, sair'
  },
  StatusCode: {
    DRAFT: 'Rascunho',
    PREPARING_LEVER: 'Em preparação',
    SCHEDULED: 'Agendado',
    ACTIVE: 'Ativo',
    ENDED: 'Encerrado',
    ERROR: 'Erro'
  },
  SettingsCard: {
    Title: 'Configurações',
    EditButton: 'Editar',
    OfferName: 'Nome da oferta',
    OfferId: 'Código da oferta',
    Period: 'Período',
    PeriodSeparator: 'to',
    Status: 'Status',
    Skus: 'SKUs'
  },
  TouchpointsVisibilityCard: {
    Touchpoints: 'Touchpoints',
    TouchpointDetails: 'Detalhes do touchpoint',
    ChallengeDateRange: 'Intervalo de datas do desafio',
    ChallengeDisplay: 'Exibição do desafio',
    Points: 'Pontos',
    CoverImage: 'Imagem da capa',
    CollumGridtitle: 'Título',
    CampaignGroupId: 'ID do grupo da campanha (opcional)',
    Description: 'Descrição',
    Allocation: 'Alocação',
    EditButton: 'Editar',
    RewardsCampaignId: 'ID do desafio de recompensas',
    Units: 'Unidades',
    OfferDateRange: 'Intervalo de datas da oferta',
    DiscountDateRange: 'Intervalo de datas do touchpoint',
    BudgetId: 'ID do orçamento',
    Eligible: 'Máximo de pedidos por PDV',
    SkuLimit: 'Máximo de SKUs por PDV',
    DiscountAndQuantitiesFields: {
      Min: 'Quantidade mínima por pedido',
      Max: 'Quantidade máxima por pedido',
      Discount: 'Discount',
      MinDiscount: 'Desconto mínimo',
      MaxDiscount: 'Desconto máximo'
    },
    TableDiscountAndQuantities: {
      Tier: 'Nível',
      Min: 'Quantidade mínima por pedido',
      Max: 'Quantidade máxima por pedido',
      Discount: 'Descontos %',
      Actions: 'Remover',
      EmptyMessage: 'Nenhum desconto foi adicionado ainda.',
      ButtonAddTier: 'Adicionar nível'
    }
  },
  // NEED COPY DECK
  Touchpoints: {
    Category: {
      POINTS_BASED: 'Ofertas baseadas em pontos',
      POINTS_BASED_MULTIPLE_ORDER: 'Ofertas baseadas em pontos',
      POINTS_BASED_VOLUME_FIXED: 'Ofertas baseadas em pontos',
      PROMOTIONS: 'Promoções',
      PROMOTIONS_PERSONALIZED: 'Promoções personalizadas',
      PROMOTIONS_DISCOUNT: 'Promoções',
      PROMOTIONS_TIERED_DISCOUNT: 'Promoções',
      REWARDS_CHALLENGE: 'Ofertas baseadas em pontos',
      REWARDS_CHALLENGE_MULTIPLE_ORDER: 'Ofertas baseadas em pontos'
    },
    DiscountsAndQuantities: {
      MIN_QUANTITY: 'Min. quantity',
      QUANTITY_PLACEHOLDER: 'Enter a number',
      MAX_QUANTITY: 'Max. quantity',
      DISCOUNT: 'Discount',
      DISCOUNT_PLACEHOLDER: 'Enter a discount percentage'
    },
    Type: {
      POINTS_BASED_MULTIPLE_ORDER: 'Pedido múltiplo',
      SINGLE_PURCHASE: 'Single order',
      DISCOUNTS: 'Discount',
      PROMOTIONS_TIERED_DISCOUNT: 'Descontos progressivos',
      STEPPED_DISCOUNTS: 'Stepped discount',
      INTERACTIVE_COMBOS: 'Interactive combos',
      FIXED_COMBOS: 'Fixed combos',
      POINTS_BASED_VOLUME_FIXED: 'Fixado por volume',
      SPEND_BASED: 'Spend based',
      VOLUME_FIXED: 'Volume fixed',
      PROMOTIONS_DISCOUNT: 'Descontos',
      PROMOTIONS_DISCOUNT_QUANTITY: 'Descontos e quantidades',
      REWARDS_CHALLENGE: 'Pedido múltiplo',
      REWARDS_CHALLENGE_MULTIPLE_ORDER: 'Pedido múltiplo'
    },
    TouchpointsDescriptions: {
      POINTS_BASED_MULTIPLE_ORDER:
        'Uma oferta que permite que os PDVs ganhem pontos comprando os SKUs necessários em vários pedidos dentro de um prazo definido.',
      SINGLE_PURCHASE:
        'Specific purchase quantity per SKU that can be purchased in one single order within the challenge period.',
      POINTS_BASED_VOLUME_FIXED:
        'Uma oferta que permite que os PDVs ganhem pontos comprando um número necessário de SKUs dentro de um prazo definido.',
      SPEND_BASED:
        'Overall purchase value ($$), the user can of which SKU from the challenge SKU list, that can be purchased in multiple orders within the challenge period.',
      DISCOUNTS:
        'Minimum and Maximum purchase quantity per SKU, that every time the customers reaches that quantity in an order within the promotion period they will get the benefit, but only for a limited quantity.',
      PROMOTIONS_TIERED_DISCOUNT:
        'Os PDVs que atingirem a quantidade necessária dentro do período promocional receberão um desconto que aumenta à medida que os PDVs compram mais produtos, com cada etapa oferecendo um desconto maior do que o anterior.',
      STEPPED_DISCOUNTS:
        'Specific minimum purchase quantity per SKUs and specific benefit per range of purchase quantity, every time the customers reaches a specific range minimum purchase quantity the benefit will increase.',
      FIXED_COMBOS:
        'Specific minimum purchase quantity per SKU that can be purchased in one single order within the promotion period.',
      INTERACTIVE_COMBOS:
        'Overall purchase quantity cross SKU or Minimum and Maximum purchase quantity per SKU that can be purchased in one single order within the promotion period.',
      PROMOTIONS_DISCOUNT:
        'Os PDVs que atingirem a quantidade necessária dentro do período promocional receberão um desconto, mas apenas por uma quantidade limitada.',
      PROMOTIONS_DISCOUNT_QUANTITY: 'The values below will be applied to all selected SKUs.'
    }
  },
  Toast: {
    Success: 'Campaign created',
    Error: 'Something went wrong. Try again.'
  },
  SideBar: {
    AudienceBuilder: 'Criador de audiências'
  },
  Countries: {
    BR: 'Brasil',
    MX: 'México',
    CO: 'Colômbia',
    AR: 'Argentina',
    ZA: 'África do Sul'
  }
};

export default ptBR;
