import { useAuthenticationService } from 'admin-portal-shared-services';
import React, { useEffect, useMemo } from 'react';
import { useGetUserPreferences } from '../../../hooks/useGetUserPreferences';
import { useHeader } from '../../../hooks/useHeader';
import { useNotification } from '../../../hooks/useNotification';

export const WsNotification: React.FC = () => {
  const authentication = useAuthenticationService().getAuthHeader();
  const { user } = useGetUserPreferences();

  const { vendorId, country } = useHeader();
  const { notify } = useNotification();

  const params = useMemo(() => {
    const data = {
      vendorId,
      country,
      token: authentication,
    };

    return `status=PENDING,ANALYZING,PROCESSING&data=${btoa(JSON.stringify(data))}&userId=${
      user.id
    }`;
  }, [vendorId, country, authentication, user.id]);

  useEffect(() => {
    const { REACT_APP_BFF_URL } = process.env;

    const newSocket = new WebSocket(
      `wss://${window.location.hostname}${REACT_APP_BFF_URL}/ws/?${params}`
    );

    newSocket.onopen = () => {
      console.log('WebSocket connection established');
    };

    newSocket.onmessage = (event) => {
      notify(JSON.parse(event.data));
    };

    newSocket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      newSocket.close();
    };
  }, []);

  return null;
};
