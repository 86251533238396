import { useNavigate } from 'react-router-dom';

import { Table } from '@hexa-ui/components';

import { PATH_BASE, PATH_HOME } from '@/consts/route';
import * as Styled from './TableVisits.styles';

import { bdrsMock } from '../../consts/BDRs.mock';
import { useTableVisits } from '../../hooks/useTableVisits/useTableVisits';

export const TableVisits = () => {
  const navigate = useNavigate();
  const { columns } = useTableVisits();

  return (
    <Styled.TableWrapper>
      <Table
        columns={columns}
        data={bdrsMock}
        pagination={{
          pageSize: 25,
        }}
        onRow={(rowData) => ({
          'data-testid': `visit-row-${rowData.id}`,
          onClick: () => navigate(`${PATH_HOME}${PATH_BASE}/${rowData.id}`),
        })}
      />
    </Styled.TableWrapper>
  );
};
