import { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { useRouterViewModel } from './viewModel/routerViewModel';

const Router = (): JSX.Element => {
  const {
    baseRoute,
    DriverPortfolioPage,
    DriverProfilePage,
    RoutesPage,
    CreateRoutesPage,
  } = useRouterViewModel();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={baseRoute}
          element={<Navigate to={DriverPortfolioPage.path} />}
        />
        <Route
          path={DriverPortfolioPage.path}
          element={
            <Suspense fallback={<div>Loading</div>}>
              <DriverPortfolioPage.Element />
            </Suspense>
          }
        />
        <Route
          path={DriverProfilePage.path}
          element={
            <Suspense fallback={<div>Loading</div>}>
              <DriverProfilePage.Element />
            </Suspense>
          }
        />
        <Route
          path={RoutesPage.path}
          element={
            <Suspense fallback={<div>Loading</div>}>
              <RoutesPage.Element />
            </Suspense>
          }
        />
        <Route
          path={CreateRoutesPage.path}
          element={
            <Suspense fallback={<div>Loading</div>}>
              <CreateRoutesPage.Element />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
