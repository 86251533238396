import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNotificationMassApproval } from '../../../hooks/useNotificationMassApproval';
import { useRequester } from '../../../hooks/useRequester';
import { getFiles } from '../../../services';
import { adapterRecommendation } from './AdapterRecommendation';
import { handleRecommendationStatus } from './utils';

export const SSENotificationMassApproval: React.FC = () => {
  const { get } = useRequester();
  const { pathname } = useLocation();

  const { notify } = useNotificationMassApproval();
  const getData = async () => {
    const response = await getFiles({
      api: { get },
      origin: 'ASYNC_UPDATE',
      queryParams: '&status=ANALYZING,ANALYZED,PROCESSING,DONE,FAILED',
    });

    if (!response?.success || !response.data[0]) {
      return;
    }
    const latestRecommendation = response.data[0];
    const recommendation = adapterRecommendation(latestRecommendation);
    handleRecommendationStatus(recommendation, notify);
  };

  useEffect(() => {
    getData();
  }, [pathname]);

  return null;
};
