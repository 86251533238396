import {
  createIntl,
  createIntlCache,
  defineMessages,
  IntlShape,
} from "react-intl";
import MessageMap from "../domains/MessageMap";
import { translationID } from "../locale-data/translationID";
import { translationEN } from "../locale-data/translationEN";
import { translationPT } from "../locale-data/translationPT";
import { translationES } from "../locale-data/translationES";

interface FlatMessageMap {
  [key: string]: string;
}

export const DEFAULT_LANGUAGE = "en-US";

const MESSAGES: { [language: string]: MessageMap } = {
  "en-US": translationEN,
  "pt-BR": translationPT,
  "es-419": translationES,
  "id-ID": translationID,
};

const flattenMessages = (nestedMessages: MessageMap): FlatMessageMap =>
  Object.entries(nestedMessages).reduce(
    (messages, [messageKey, message]) =>
      Object.assign(
        messages,
        Object.fromEntries(
          Object.entries(message).map(([key, value]) => [
            `${messageKey}.${key}`,
            value,
          ]),
        ),
      ),
    {},
  );

/* istanbul ignore next */
const validateSelectedLanguage = (language: string) => {
  if (!MESSAGES[language]) {
    return DEFAULT_LANGUAGE;
  }
  return language;
};

const defaultMessageMap = flattenMessages(MESSAGES[DEFAULT_LANGUAGE]);

const fillFlattenedMessaged = (messageMap: FlatMessageMap): FlatMessageMap => ({
  ...defaultMessageMap,
  ...messageMap,
});

const intlProvider = (locale: string): IntlShape => {
  const cache = createIntlCache();
  const language = validateSelectedLanguage(locale);
  return createIntl(
    {
      locale: language,
      messages: defineMessages(
        fillFlattenedMessaged(flattenMessages(MESSAGES[language])),
      ),
    },
    cache,
  );
};

export default intlProvider;
