export const handleRecommendationStatus = (
  recommendation,
  cb,
  idKeyMassApproval = 'latestRecommendationId',
  statusKeyMassApproval = 'latestRecommendationStatus'
) => {
  const latestRecommendationStatus = localStorage.getItem(statusKeyMassApproval);
  const latestRecommendationId = localStorage.getItem(idKeyMassApproval);

  if (
    !latestRecommendationStatus ||
    (recommendation.status !== latestRecommendationStatus &&
      latestRecommendationId === recommendation.id)
  ) {
    cb({ ...recommendation, previousStatus: latestRecommendationStatus });
    localStorage.setItem(statusKeyMassApproval, recommendation.status);
  }
  localStorage.setItem(idKeyMassApproval, recommendation.id);
};
