import { Badge } from '@hexa-ui/components';
import styled from 'styled-components';

import { BadgeStatusProps } from '../../types/TableVisitCard';

export const BadgeStatus = styled(Badge.Status)<BadgeStatusProps>`
  background-color: ${(props) => props.$bgcolor};

  > p {
    color: ${(props) => props.$labelcolor};
    display: flex;
  }
  > p::before {
    content: '•';
    color: ${(props) => props.$labelcolor};
    font-size: 1.2em;
    margin-right: 4px;
  }
`;

export const TableWrapper = styled.div`
  [data-testid='SortDescending'],
  [data-testid='SortAscending'],
  [data-testid='SortDefault'] {
    height: 20px;
    width: 20px;
    margin-left: 18px;
  }

  [data-testid='SortDefault'] {
    visibility: inherit;
  }
`;